import React, { useEffect, useState } from 'react'
import download from "../../../assets/images/cardTemplates/download.png";
import logo from "../../../assets/images/cardTemplates/folwerWithoutname-removebg-preview.png";
import AdmitCardRoutine from './AdmitCardRoutine';
import { URL } from '../../../utils/Constants';
import _, { groupBy } from 'lodash';
import moment from 'moment';

function AdmitCardTemplate1(props) {

    const [filteredTable, setFilteredTable] = useState(null);
    const [filtereduniqueDates, setFilteredUniqueDates] = useState(null);
    const [routineData, setRoutineData] = useState(null);

    useEffect(() => {
        if (props.student !== undefined) {
            transformstudentData(props?.student?.subjects);
            setRoutineData(props?.student?.subjects);
        }
    }, [props?.studentInfos]);

    const transformstudentData = (data) => {
        const uniqueDates = Array.from(
            new Set(data.flatMap((d) => [
                d?.assessmentTheoryFromDate &&
                moment(d.assessmentTheoryFromDate).format("YYYY-MM-DD"),
                d?.assessmentPracticalFromDate &&
                moment(d.assessmentPracticalFromDate).format("YYYY-MM-DD")
            ]))
        ).filter((item) => !!item).sort();

        const groupedByDateTheory = groupBy(data, (entry) => moment(entry.assessmentTheoryFromDate).format("YYYY-MM-DD"));
        const groupedByDatePractical = groupBy(data, (entry) => moment(entry.assessmentPracticalFromDate).format("YYYY-MM-DD"));

        const mappedSubjects = Object.entries(groupedByDateTheory).concat(Object.entries(groupedByDatePractical))
            .map(([date, entries]) => {
                const datePart = date.split(' ')[0];
                return entries.map((entry) => {
                    let theoryDate;
                    let practicalDate;
                    if (entry?.assessmentTheoryFromDate) {
                        theoryDate = moment(entry?.assessmentTheoryFromDate).format("YYYY-MM-DD") === datePart;
                    }
                    if (entry?.assessmentPracticalFromDate) {
                        practicalDate = moment(entry?.assessmentPracticalFromDate).format("YYYY-MM-DD") === datePart;
                    }
                    let time = '';
                    let subjectType = '';
                    if (theoryDate) {
                        time = `Th-${moment(entry.assessmentTheoryFromDate).format("hh:mm A")} to ${moment(entry.assessmentTheoryToDate).format("hh:mm A")}`;
                        subjectType = 'Th';
                    }
                    if (practicalDate) {
                        if (time) {
                            time += ` & Pr-${moment(entry.assessmentPracticalFromDate).format("hh:mm A")} to ${moment(entry.assessmentPracticalToDate).format("hh:mm A")}`;
                            subjectType = 'Th & Pr';
                        } else {
                            time = `Pr-${moment(entry.assessmentPracticalFromDate).format("hh:mm A")} to ${moment(entry.assessmentPracticalToDate).format("hh:mm A")}`;
                            subjectType = 'Pr';
                        }
                    }
                    return {
                        subject: entry.name,
                        date: datePart,
                        time: time,
                        subjectType: subjectType
                    };
                });
            });

        const newTable = mappedSubjects.flat().reduce((acc, curr) => {
            if (!acc[curr.date]) {
                acc[curr.date] = [];
            }
            if (curr.time !== '') {
                const existingSubject = acc[curr.date].find((subject) => subject.startsWith(curr.subject));
                if (!existingSubject) {
                    acc[curr.date].push(`${curr.subject} (${curr.subjectType})`);
                }
            }
            return acc;
        }, {});

        setFilteredTable(newTable);
        setFilteredUniqueDates(uniqueDates);
        return { table: newTable, uniqueDates };
    };

    return (
        <div className='admitCardContainer'>
            <div className='row mb-2 mt-3'>
                <div className='col-md-2 col-2 d-flex justify-content-center align-item-center'>
                    <div className='d-flex align-items-center justify-content-center'>
                        {
                            props?.schoolInfos?.imageName &&
                            (
                                <>
                                    <img style={{ width: "100px", height: "100px" }} src={`${URL.imageSelectURL}${props?.schoolInfos?.imageName}`} alt='logo' />
                                </>)
                        }
                    </div>
                </div>
                <div className='col-md-8 col-8 d-flex justify-content-center align-item-center flex-column'>
                    <div className='d-flex justify-content-center align-items-center mb-1'>
                        <b>
                            {props?.schoolInfos?.name ? `${props?.schoolInfos?.name}` : `---------------`}
                            {props?.schoolInfos?.acronym ? ` (${props?.schoolInfos?.acronym})` : null}
                        </b>
                    </div>
                    <div className='d-flex justify-content-center align-items-center mb-1'>
                        {props?.schoolInfos?.address ? `${props?.schoolInfos?.address}` : `---------------`}
                    </div>
                    <div className='d-flex justify-content-center align-items-center mb-1'>
                        {!!!props?.assessmentSubjectValue?.assessmentName ? `---------------` : `${props.assessmentSubjectValue?.assessmentName}`}

                    </div>
                    <div className='d-flex justify-content-center align-items-center'>
                        <p className='px-5 p-2' style={{ border: "1px solid black", borderRadius: "12px" }}>
                            Student Admit Card
                        </p>
                    </div>
                </div>
                <div className='col-md-2 col-2 d-flex justify-content-start align-item-center'>
                    <div className='d-flex align-items-center justify-content-center'>
                        <img style={{ width: "100px", height: "100px" }} src={props?.student?.photo ? props?.student?.photo : download} alt='logo' />
                    </div>
                </div>
            </div>
            <div className='row my-2 py-2'>
                <div className='col-4 d-flex justify-content-center align-item-center'>
                    <div className="col-10">
                        {/* p-2 */}
                        <div
                            className="form-group row"
                            style={{ marginBottom: "20px !important" }}
                        >
                            <div className="col-md-12">
                                <label htmlFor="studentName">
                                    {" "}
                                    <b>
                                        Name :{" "}
                                    </b>
                                    {props?.student?.people?.name}
                                </label>
                            </div>
                        </div>

                        <div
                            className="form-group row"
                            style={{ marginBottom: "20px !important" }}
                        >
                            <div className="col-md-12">
                                <label htmlFor="classProgramme">
                                    <b>
                                        Programme, Class :{" "}
                                    </b>{" "}
                                    {props?.classValue?.name ? `${props?.classValue?.name}` : `---------------`}
                                    {/* {props?.className ? `, ${props?.className}` : null} */}
                                    {props?.student ? `, ${props?.student?.yearLevel?.name}` : null}
                                </label>
                            </div>
                            {" "}
                        </div>

                        <div
                            className="form-group row"
                            style={{ marginBottom: "20px !important" }}
                        >
                            <div className="col-md-12">
                                <label htmlFor="validityDate">
                                    <b>
                                        Symbol No :{" "}
                                        {" "}
                                    </b>
                                    12345{" "}
                                </label>
                            </div>
                            {" "}
                        </div>

                        <div
                            className="form-group row"
                            style={{ marginBottom: "20px !important" }}
                        >
                            <div className="col-md-12">
                                <label htmlFor="digitalSignature">
                                    <b>
                                        Roll No :{" "}
                                        {" "}
                                    </b>
                                    12
                                </label>
                            </div>
                            {" "}
                        </div>

                        <div
                            className="form-group row"
                            style={{ marginBottom: "20px !important" }}
                        >
                            <div className="col-md-12">
                                <label htmlFor="digitalSignature">
                                    <b>Hall No :{" "}
                                    </b>
                                    1A
                                </label>
                            </div>
                            {" "}
                        </div>

                    </div>
                </div>
                <div className='col-8 d-flex justify-content-center align-item-center'>
                    <div className='col-10'>
                        {(props?.assessmentSubjectValue && props?.studentFetched
                            // && props?.classRooms
                        ) ?
                            (
                                <>
                                    <AdmitCardRoutine
                                        classValue={props?.classValue}
                                        assessmentSubjectValue={props?.assessmentSubjectValue}
                                        classRooms={props?.classRooms}
                                        routineData={routineData}
                                        filteredTable={filteredTable}
                                        filtereduniqueDates={filtereduniqueDates}
                                        studentInfos={props?.studentInfos}
                                    />
                                </>) :
                            (
                                <>
                                    No routine Found
                                </>
                            )
                        }
                        {/* <table className='table table-bordered table-striped'>
                            <thead className='tt-group-header'>
                                <tr>
                                    <th>
                                        <div className='d-flex align-items-center justify-content-center w-100'>
                                            S.N.
                                        </div>
                                    </th>
                                    <th>
                                        <div className='d-flex align-items-center justify-content-center w-100'>
                                            Date
                                        </div>
                                    </th>
                                    <th>
                                        <div className='d-flex align-items-center justify-content-center w-100'>
                                            Subject
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className='mt-1 d-flex align-items-center justify-content-center w-100'>
                                            1
                                        </div>
                                    </td>
                                    <td>
                                        <div className='mt-1 d-flex align-items-center justify-content-center w-100'>
                                            2081-10-08
                                        </div>
                                    </td>
                                    <td>
                                        <div className='mt-1 d-flex align-items-center justify-content-center w-100'>
                                            Science
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='mt-1 d-flex align-items-center justify-content-center w-100'>
                                            2
                                        </div>
                                    </td>
                                    <td>
                                        <div className='mt-1 d-flex align-items-center justify-content-center w-100'>
                                            2081-10-09
                                        </div>
                                    </td>
                                    <td>
                                        <div className='mt-1 d-flex align-items-center justify-content-center w-100'>
                                            Maths
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='mt-1 d-flex align-items-center justify-content-center w-100'>
                                            3
                                        </div>
                                    </td>
                                    <td>
                                        <div className='mt-1 d-flex align-items-center justify-content-center w-100'>
                                            2081-10-10
                                        </div>
                                    </td>
                                    <td>
                                        <div className='mt-1 d-flex align-items-center justify-content-center w-100'>
                                            English
                                        </div>
                                    </td>
                                </tr>
                            </tbody>

                        </table> */}
                    </div>
                </div>
            </div>
            <div className='row mt-2 mx-2 py-3'>
                {/* <div className='col-3 d-flex justify-content-center align-item-center'>
                    Class Teacher : ____________
                </div> */}
                <div className='col-6 d-flex justify-content-start align-item-center'>
                    Note :
                    {props?.note ? ` ${props?.note}` : ` ____________`}
                </div>
                <div className='col-6 d-flex justify-content-center align-item-center'>
                    Principal : ____________
                </div>
            </div>
        </div>
    )
}

export default AdmitCardTemplate1