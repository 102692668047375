import React, { useEffect, useState, useRef } from 'react';
import Select from "react-select";
import makeAnimated from "react-select/animated";
import ExamModuleView from './ExamModuleView';
import { checkWidgetAdminOrNot, URL, userRole, userRoleCode } from '../../utils/Constants';
import ModalWindow from "./../../UI/ModalWindow";
import GranularPermissionModal from '../Permissions/GranularPermissionModal';
import { axiosPost } from '../../utils/AxiosApi';
import _, { groupBy } from 'lodash';
import moment from 'moment';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import ExamModuleViewStudent from './ExamModuleViewStudent';

const animatedComponents = makeAnimated();

function ExamModuleNew(props) {

  const [educationLevel, setEducationLevel] = useState({});
  const [educationLevelAllData, setEducationLevelAllData] = useState(null);
  const [classValue, setClassValue] = useState(null);

  const [classRooms, setClassRooms] = useState(null);
  const [classroomsList, setClassroomsList] = useState([]);

  const [assessmentSubjectList, setAssessmentSubjectList] = useState({});
  const [assessmentAllData, setAssessmentAllData] = useState(null);
  const [assessmentSubjectValue, setAssessmentSubjectValue] = useState(null);

  const [routineData, setRoutineData] = useState(null);

  const [filteredTable, setFilteredTable] = useState(null);
  const [filtereduniqueDates, setFilteredUniqueDates] = useState(null);

  const [permissionModal, setPermissionModal] = useState(null);

  // new states for student data fetch of exam routine
  const [schoolInfos, setSchoolInfos] = useState(null);
  const [studentDetail, setStudentDetail] = useState(null);
  const [peopleIdVal, setPeopleIdVal] = useState(localStorage.getItem("peopleId"));
  const [isStudent, setIsStudent] = useState(false);
  const [yearLevelId, setYearLevelId] = useState(null);

  // new states for parent data fetch of exam routine
  const [isparent, setIsParent] = useState(false);
  const [studentValue, setStudentValue] = useState(null);
  const [studentList, setStudentList] = useState([]);
  const [studentOptions, setStudentOptions] = useState(null);

  const componentRef = useRef();

  const printFunction = useReactToPrint({
    content: () => componentRef.current,
    orientation: 'landscape',
    removeAfterPrint: true,
    documentTitle:`${assessmentSubjectValue?.label}${classRooms?.label?"-"+classRooms?.label:""}`
  });

  useEffect(() => {
    let allData = educationLevelAllData;
    let selectedData = classValue;
    if (allData && selectedData) {
      let yearLevels = allData.filter((item) => item.id === selectedData.id)[0]?.yearLevels;
      let YearLevelOptions = yearLevels?.map((item) => ({
        ...item,
        value: item.id,
        label: item.name,
      }))
      setClassroomsList(YearLevelOptions);
    }
    else {
      setClassroomsList({})
    }
  }, [classValue]);

  useEffect(() => {
    let assessmentallDatas = assessmentAllData;
    let selectedData = classValue;
    if (assessmentallDatas && selectedData) {
      let assessmentOptions = assessmentallDatas?.map((item) => ({
        ...item,
        value: item.id,
        label: item.assessmentName
      }));
      setAssessmentSubjectList(assessmentOptions);
    }
  }, [assessmentAllData]);


  useEffect(() => {
    getEducationLevels();
    getSchoolInfo();
    if (userRole.toString() == userRoleCode.roleStudent) {
      setIsStudent(true);
      getStudentDetail();
    } else if (userRole.toString() == userRoleCode.roleStudentContact) {
      setIsParent(true);
      getStudentByContactPeople();
    }
    else {
      // getTeachers();
      // getEducationLevels();
    }
  }, []);

  useEffect(() => {
    if (!!isStudent && studentDetail) {
      let studentDetails = studentDetail;
      setClassValue(studentDetails?.educationLevel?.id);
      setClassRooms(studentDetails?.classroom?.id);
      setYearLevelId(studentDetails?.yearLevel?.id);
      let data = {
        educationLevelId: studentDetails?.educationLevel?.id,
        yearLevelId: studentDetails?.yearLevel?.id,
        classroomId: studentDetails?.classroom?.id
      }
      axiosPost(URL.getAssessmentWithScaleOrComment, data, (response) => {
        if (response.data.status === 200) {
          let assessmentOptions = response.data.data?.map((item) => ({
            ...item,
            value: item.id,
            label: item.assessmentName
          }));
          setAssessmentSubjectList(assessmentOptions);
        }
      });
    }
    else if (!!isparent && studentDetail) {
      let studentDetails = studentDetail;
      setClassValue(studentDetails?.educationLevel?.id);
      setClassRooms(studentDetails?.classroom?.id);
      setYearLevelId(studentDetails?.yearLevel?.id);
      let data = {
        educationLevelId: studentDetails?.educationLevel?.id,
        yearLevelId: studentDetails?.yearLevel?.id,
        classroomId: studentDetails?.classroom?.id
      }
      axiosPost(URL.getAssessmentWithScaleOrComment, data, (response) => {
        if (response.data.status === 200) {
          let assessmentOptions = response.data.data?.map((item) => ({
            ...item,
            value: item.id,
            label: item.assessmentName
          }));
          setAssessmentSubjectList(assessmentOptions);
        }
      });
    }
  }, [studentDetail]);

  useEffect(() => {
    if (studentList?.length > 0) {
      let students =
        studentList?.map((item) => ({
          ...item,
          value: item.id,
          label: item.name
        }));
      setStudentOptions(students);
    }
  }, [studentList]);


  const handleClassChange = (value) => {
    setClassValue(value);
    setAssessmentSubjectValue(null);
    setClassRooms(null);
    getAssessments(value?.id)

  };
  const handleAssessmentListChange = (value) => {
    setAssessmentSubjectValue(value);
    setClassRooms(null);
    if (value?.id && !!!isStudent && !!!isparent) {
      getRoutineData(value?.id, classRooms?.id)
    }
    if (value?.id && !!isStudent) {
      getRoutineDataStudent(value?.id)
    }
    if (value?.id && !!isparent) {
      getRoutineDataStudent(value?.id)
    }

  };
  const handleClassRoomListChange = (value) => {
    setClassRooms(value);
    if (value?.id) {
      getRoutineData(assessmentSubjectValue?.id, value?.id)
    }
    else {
      getRoutineData(assessmentSubjectValue?.id, null)
    }
  };

  const getAssessments = (param) => {
    let data = {
      educationLevelId: param
    };
    axiosPost(URL.getAssessmentWithScaleOrComment, data, (response) => {
      if (response.data.status === 200) {
        setAssessmentAllData(response.data.data);
      }
    });
  }

  const getEducationLevels = () => {
    axiosPost(URL.getEducationLevel, {}, (response) => {
      if (response.data.status === 200) {
        const data = response.data.data;
        setEducationLevelAllData(data);
        setEducationLevel(
          data?.map((item) => ({
            ...item,
            value: item.id,
            label: item.name,
          }))
        );
      }
    });
  };

  const getRoutineData = (assessId, yearLevelId) => {
    let data = {
      assessmentId: assessId,
      educationLevelId: classValue?.id,
      yearLevelId: yearLevelId ? yearLevelId : null,
    }
    axiosPost(URL.getRoutineData, data, (response) => {
      if (response.data.status === 200) {
        setRoutineData(response.data.data);
        transformData(response.data.data)
      }
    });
  }

  // const transformData = (data) => {
  //   const uniqueDates = Array.from(
  //     new Set(data.flatMap((d) => [
  //       d?.assessmentTheoryFromDate &&
  //       moment(d.assessmentTheoryFromDate).format("YYYY-MM-DD"),
  //       d?.assessmentPracticalFromDate &&
  //       moment(d.assessmentPracticalFromDate).format("YYYY-MM-DD")
  //     ]))
  //   ).filter((item) => !!item).sort();

  //   const groupedByYearLevelAndClassroom = groupBy(data, (entry) => `${entry.yearLevelName} (${entry.classroomName})`);
  //   const table = [];

  //   Object.entries(groupedByYearLevelAndClassroom).forEach(([key, entries]) => {
  //     const row = { yearLevelName: key };
  //     uniqueDates.forEach((date) => {
  //       const matchingEntry = entries.filter((entry) => {
  //         let assessmentTheoryFromDate = (moment(entry?.assessmentTheoryFromDate).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD")) && entry.hasTheory
  //         let assessmentPracticalFromDate = (moment(entry?.assessmentPracticalFromDate).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD")) && entry.hasPractical;
  //         if (assessmentTheoryFromDate || assessmentPracticalFromDate) {
  //           return entry
  //         }
  //       });
  //       const mappedData = matchingEntry.map((item) => {
  //         let theoryDate;
  //         let practicalDate;
  //         if (item?.assessmentTheoryFromDate) {
  //           theoryDate = moment(item?.assessmentTheoryFromDate).format("YYYY-MM-DD") === date;
  //         }
  //         if (item?.assessmentPracticalFromDate) {
  //           practicalDate = moment(item?.assessmentPracticalFromDate).format("YYYY-MM-DD") === date
  //         }
  //         return (
  //           `${item.name}
  //           (${theoryDate ? `Th-${moment(item.assessmentTheoryFromDate).format("hh:mm A")}` : ``}
  //           ${practicalDate && theoryDate ? " & " : ""}
  //           ${practicalDate ? `Pr-${moment(item.assessmentPracticalFromDate).format("hh:mm A")}` : ``})`
  //         )
  //       });
  //       row[date] = mappedData;
  //     });
  //     table.push(row);
  //   });
  //   setFilteredTable(table);
  //   setFilteredUniqueDates(uniqueDates);
  //   return { table, uniqueDates };
  // };


  // new code after adding day value in date coulmn
  // const transformData = (data) => {
  //   const uniqueDates = Array.from(
  //     new Set(data.flatMap((d) => [
  //       d?.assessmentTheoryFromDate &&
  //       moment(d.assessmentTheoryFromDate).format("YYYY-MM-DD"),
  //       d?.assessmentPracticalFromDate &&
  //       moment(d.assessmentPracticalFromDate).format("YYYY-MM-DD")
  //     ]))
  //   ).filter((item) => !!item).sort().map((date) => {
  //     const day = moment(date).format("dddd");
  //     return `${date} (${day})`;
  //   });

  //   const groupedByYearLevelAndClassroom = groupBy(data, (entry) => `${entry.yearLevelName} (${entry.classroomName})`);
  //   const table = [];

  //   Object.entries(groupedByYearLevelAndClassroom).forEach(([key, entries]) => {
  //     const row = { yearLevelName: key };
  //     uniqueDates.forEach((date) => {
  //       const datePart = date.split(' ')[0];
  //       const matchingEntry = entries.filter((entry) => {
  //         let assessmentTheoryFromDate = (moment(entry?.assessmentTheoryFromDate).format("YYYY-MM-DD") === datePart) && entry.hasTheory
  //         let assessmentPracticalFromDate = (moment(entry?.assessmentPracticalFromDate).format("YYYY-MM-DD") === datePart) && entry.hasPractical;
  //         if (assessmentTheoryFromDate || assessmentPracticalFromDate) {
  //           return entry
  //         }
  //       });
  //       const mappedData = matchingEntry.map((item) => {
  //         let theoryDate;
  //         let practicalDate;
  //         if (item?.assessmentTheoryFromDate) {
  //           theoryDate = moment(item?.assessmentTheoryFromDate).format("YYYY-MM-DD") === datePart;
  //         }
  //         if (item?.assessmentPracticalFromDate) {
  //           practicalDate = moment(item?.assessmentPracticalFromDate).format("YYYY-MM-DD") === datePart
  //         }
  //         return (
  //           `${item.name}(${theoryDate ? `Th-${moment(item.assessmentTheoryFromDate).format("hh:mm A")}` : ``}${practicalDate && theoryDate ? " & " : ""}${practicalDate ? `Pr-${moment(item.assessmentPracticalFromDate).format("hh:mm A")}` : ``})`
  //         )
  //       });
  //       row[date] = mappedData.length > 0 ? mappedData : ['-'];
  //       // row[date] = mappedData;
  //     });
  //     table.push(row);
  //   });
  //   setFilteredTable(table);
  //   setFilteredUniqueDates(uniqueDates);
  //   return { table, uniqueDates };
  // };

  const transformData = (data) => {
    const uniqueDates = Array.from(
      new Set(data.flatMap((d) => [
        d?.assessmentTheoryFromDate &&
        moment(d.assessmentTheoryFromDate).format("YYYY-MM-DD"),
        d?.assessmentPracticalFromDate &&
        moment(d.assessmentPracticalFromDate).format("YYYY-MM-DD")
      ]))
    ).filter((item) => !!item).sort().map((date) => {
      const day = moment(date).format("dddd");
      return `${date} (${day})`;
    });

    const groupedByYearLevelAndClassroom = groupBy(data, (entry) => `${entry.yearLevelName} (${entry.classroomName})`);
    const table = [];

    Object.entries(groupedByYearLevelAndClassroom).forEach(([key, entries]) => {
      const row = { yearLevelName: key };
      uniqueDates.forEach((date) => {
        const datePart = date.split(' ')[0];
        const matchingEntry = entries.filter((entry) => {
          let assessmentTheoryFromDate = (moment(entry?.assessmentTheoryFromDate).format("YYYY-MM-DD") === datePart) && entry.hasTheory
          let assessmentPracticalFromDate = (moment(entry?.assessmentPracticalFromDate).format("YYYY-MM-DD") === datePart) && entry.hasPractical;
          if (assessmentTheoryFromDate || assessmentPracticalFromDate) {
            return entry
          }
        });
        const mappedData = matchingEntry.map((item) => {
          let theoryDate;
          let practicalDate;
          if (item?.assessmentTheoryFromDate) {
            theoryDate = moment(item?.assessmentTheoryFromDate).format("YYYY-MM-DD") === datePart;
          }
          if (item?.assessmentPracticalFromDate) {
            practicalDate = moment(item?.assessmentPracticalFromDate).format("YYYY-MM-DD") === datePart
          }
          return {
            subject: item.name,
            theoryTime: theoryDate ? `Th-${moment(item.assessmentTheoryFromDate).format("hh:mm A")}` : '',
            practicalTime: practicalDate ? `Pr-${moment(item.assessmentPracticalFromDate).format("hh:mm A")}` : ''
          };
        });
        row[date] = mappedData;
      });
      table.push(row);
    });
    setFilteredTable(table);
    setFilteredUniqueDates(uniqueDates);
    return { table, uniqueDates };
  };

  const transformstudentData = (data) => {
    const uniqueDates = Array.from(
      new Set(data.flatMap((d) => [
        d?.assessmentTheoryFromDate &&
        moment(d.assessmentTheoryFromDate).format("YYYY-MM-DD"),
        d?.assessmentPracticalFromDate &&
        moment(d.assessmentPracticalFromDate).format("YYYY-MM-DD")
      ]))
    ).filter((item) => !!item).sort().map((date) => {
      const day = moment(date).format("dddd");
      return `${date} (${day})`;
    });

    const groupedByDateTheory = groupBy(data, (entry) => moment(entry.assessmentTheoryFromDate).format("YYYY-MM-DD"));
    const groupedByDatePractical = groupBy(data, (entry) => moment(entry.assessmentPracticalFromDate).format("YYYY-MM-DD"));

    const mappedSubjects = Object.entries(groupedByDateTheory).concat(Object.entries(groupedByDatePractical))
      .map(([date, entries]) => {
        const datePart = date.split(' ')[0];
        return entries.map((entry) => {
          let theoryDate;
          let practicalDate;
          if (entry?.assessmentTheoryFromDate) {
            theoryDate = moment(entry?.assessmentTheoryFromDate).format("YYYY-MM-DD") === datePart;
          }
          if (entry?.assessmentPracticalFromDate) {
            practicalDate = moment(entry?.assessmentPracticalFromDate).format("YYYY-MM-DD") === datePart;
          }
          let time = '';
          if (theoryDate) {
            time = `Th-${moment(entry.assessmentTheoryFromDate).format("hh:mm A")} to ${moment(entry.assessmentTheoryToDate).format("hh:mm A")}`;
          }
          if (practicalDate) {
            if (time) {
              time += ` & Pr-${moment(entry.assessmentPracticalFromDate).format("hh:mm A")} to ${moment(entry.assessmentPracticalToDate).format("hh:mm A")}`;
            } else {
              time = `Pr-${moment(entry.assessmentPracticalFromDate).format("hh:mm A")} to ${moment(entry.assessmentPracticalToDate).format("hh:mm A")}`;
            }
          }
          return {
            subject: entry.name,
            date: datePart,
            time: time,
          };
        });
      });

    const newTable = mappedSubjects.flat().reduce((acc, curr) => {
      if (!acc[curr.date]) {
        acc[curr.date] = [];
      }
      if (curr.time !== '') {
        const existingSubject = acc[curr.date].find((subject) => subject.startsWith(curr.subject));
        if (existingSubject) {
          const existingTime = existingSubject.split('(')[1].split(')')[0];
          if (existingTime !== curr.time) {
            acc[curr.date].push(`${curr.subject} (${curr.time})`);
          }
        } else {
          acc[curr.date].push(`${curr.subject} (${curr.time})`);
        }
      }
      return acc;
    }, {});

    setFilteredTable(newTable);
    setFilteredUniqueDates(uniqueDates);
    return { table: newTable, uniqueDates };
  };

  const downloadRoutine = async () => {
    document.getElementById("printRoutine").parentNode.style.overflow =
      "visible";
    const element = document.getElementById("printRoutine");
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF({
      orientation: "landscape",
      format: "a4"
    });
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = (pdf.internal.pageSize.getWidth());
    const pdfHeight = (element.offsetHeight * pdfWidth) / imgProperties.width;
    // const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    // element.style.transform = "rotate(90deg)";
    pdf.save(`${assessmentSubjectValue?.assessmentName}-routine.pdf`);
    // element.style.transform = "rotate(-90deg)";
    // pdf.save("classRoutine.pdf");
  }

  const getSchoolInfo = () => {
    axiosPost(URL.getSchoolByKey, {}, (response) => {
      if (response.status === 200) {
        setSchoolInfos(response.data.data);
      }
    });
  }

  const getStudentDetail = (peopleId) => {
    let param = {
      peopleId: peopleId || peopleIdVal
    }
    axiosPost(URL.getStudentDetail, param, (response) => {
      if (response.status === 200) {
        let data = response.data.data;
        setStudentDetail(data);
      }
    });
  }

  const getStudentByContactPeople = () => {
    let param = {
      contactPeopleId: peopleIdVal
    }
    axiosPost(URL.getStudentByContactPeople, param, (response) => {
      if (response.data.status == 200) {
        let data = response.data.data;
        setStudentList(data)
      }
    })
  }



  const getRoutineDataStudent = (assessId) => {
    let data = {
      assessmentId: assessId,
      educationLevelId: classValue,
      yearLevelId: yearLevelId,
      classroomId: classRooms
    }
    axiosPost(URL.getRoutineData, data, (response) => {
      if (response.data.status === 200) {
        setRoutineData(response.data.data);
        transformstudentData(response.data.data);
      }
    });
  }

  const handleStudentChange = (value) => {
    let studentPeopleId = value;
    setStudentValue(studentPeopleId);
    getStudentDetail(studentPeopleId?.id);
  }

  return (
    <>
      <div className="tt-widgetContent-tab-holder" style={{ height: "79.5vh" }}>
        <div className="tt-group-header">
          Exam Module
          {/* currently commented this as no permissions required as per discussion with QA */}
          {/* {checkWidgetAdminOrNot(props.name) === true ? (
            <button
              className="tt-button tt-button-primary float-right permissionBtnCSS"
              onClick={() => {
                setPermissionModal(!permissionModal);
              }}
            >
              Permissions
            </button>
          ) : null} */}
        </div>
        <div className='tt-newListBox'>
          <div className='d-flex align-items-center py-1 justify-content-end'>
            <btn
              style={{
                pointerEvents: (!!classValue && !!assessmentSubjectValue) ? 'all' : 'none'
              }}
              className={
                (!!classValue && !!assessmentSubjectValue) ?
                  `mr-2 tt-button tt-button-primary float-right permissionBtnCSS` :
                  `mr-2 tt-button float-right permissionBtnCSS tt-disable-comment-box`
              }
              onClick={printFunction}>Download / Print Routine</btn>
            {/* commented download btn as the routine is downloadable from print preview's save as pdf feature */}
            {/* <btn
              style={{
                pointerEvents: (!!classValue && !!assessmentSubjectValue) ? 'all' : 'none'
              }}
              className={
                (!!classValue && !!assessmentSubjectValue) ?
                  `mr-2 tt-button tt-button-primary float-right permissionBtnCSS` :
                  `mr-2 tt-button float-right permissionBtnCSS tt-disable-comment-box`
              }
              // className="mx-2 tt-button tt-button-primary float-right permissionBtnCSS"
              onClick={(e) => { downloadRoutine() }}>Download Routine</btn> */}
          </div>
          <div>
            <div className="d-flex justify-content-between" style={{ gap: "1rem" }}>
              {
                !!isparent &&
                (
                  <>
                    <div className="w-100">
                      <strong>Student</strong>
                      <Select
                        className="w-100"
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        value={studentValue}
                        options={studentOptions}
                        name="class"
                        onChange={handleStudentChange}
                        placeholder="Select Student"
                        isClearable
                      />
                    </div>
                  </>)
              }
              {
                (!!!isStudent && !!!isparent) &&
                (
                  <>
                    <div className="w-100">
                      <strong>Education Level</strong>
                      <Select
                        className="w-100"
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        value={classValue}
                        options={educationLevel}
                        name="class"
                        onChange={handleClassChange}
                        placeholder="Select Level"
                        isClearable
                      />
                    </div>
                  </>)
              }
              <div className='w-100'>
                <strong>Assessment</strong>
                <Select
                  isDisabled={
                    (!!!classValue && !!!isStudent)
                  }
                  className="w-100"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  value={assessmentSubjectValue}
                  options={assessmentSubjectList}
                  name="assessmentList"
                  onChange={handleAssessmentListChange}
                  placeholder="Select Assessment"
                  isClearable
                />
              </div>

              {
                (!!!isStudent && !!!isparent) &&
                (
                  <>
                    <div className='w-100'>
                      <strong>Class</strong>
                      <Select
                        isDisabled={!!!assessmentSubjectValue}
                        className="w-100"
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        value={classRooms}
                        options={classroomsList}
                        name="classRooms"
                        onChange={handleClassRoomListChange}
                        placeholder="Select Class"
                        isClearable
                      />
                    </div>
                  </>)
              }

            </div>

            <div className='mt-2 d-flex align-items-center flex-column justify-content-center px-1 landscape' id='printRoutine' ref={componentRef}>

              {(classValue && assessmentSubjectValue) && (
                <div className='mt-1 w-100 d-flex justify-content-center align-item-center flex-column'>
                  <div className='d-flex justify-content-center align-items-center mb-1'>
                    <b>
                      {schoolInfos?.name}
                      {schoolInfos?.acronym && (
                        <>
                          {` (${schoolInfos?.acronym})`}
                        </>
                      )}
                    </b>
                  </div>
                  <div className='d-flex justify-content-center align-items-center mb-1'>
                    {schoolInfos?.address}
                  </div>
                  <div className='d-flex justify-content-center align-items-center mb-1'>
                    {assessmentSubjectValue?.assessmentName}
                  </div>
                </div>
              )}

              <div className='mt-1 w-100'>
                {
                  !!isStudent && (
                    <>
                      <ExamModuleViewStudent
                        classValue={classValue}
                        assessmentSubjectValue={assessmentSubjectValue}
                        routineData={routineData}
                        filteredTable={filteredTable}
                        filtereduniqueDates={filtereduniqueDates}
                        isStudent={isStudent}
                      />
                    </>
                  )
                }
                {(!!!isStudent && !!!isparent) &&
                  (
                    <>
                      <ExamModuleView
                        classValue={classValue}
                        assessmentSubjectValue={assessmentSubjectValue}
                        routineData={routineData}
                        filteredTable={filteredTable}
                        filtereduniqueDates={filtereduniqueDates}
                        isStudent={isStudent}
                      />
                    </>
                  )
                }
                {
                  !!isparent &&
                  (
                    <>
                      <ExamModuleViewStudent
                        classValue={classValue}
                        assessmentSubjectValue={assessmentSubjectValue}
                        routineData={routineData}
                        filteredTable={filteredTable}
                        filtereduniqueDates={filtereduniqueDates}
                        isStudent={isStudent}
                      />
                    </>)
                }
              </div>
            </div>
          </div>
        </div>
        <ModalWindow
          modal={permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            setPermissionModal(!permissionModal)
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName={props.name}
              moduleName="Reports"
              header="View General Assessment"
              activityName="select-general-assessment"
            />
          }
        ></ModalWindow>
      </div >
    </>
  )
}

export default ExamModuleNew    