import "jquery-ui/ui/widgets/draggable";
import $ from "jquery";
import CryptoJS from "crypto-js";
import history from "./History";
import { backend, frontend } from "./SystemConfig";
import { axiosPost } from "./AxiosApi";
import swal from "sweetalert";

/**
 * Golden ratio.
 */ export const phi = 1.6,
  reactServer = frontend,
  serverLocation = backend,
  build = "",
  YEARLEVEL_CLASSROOM_DEPENDENCY = "YEARLEVEL_CLASSROOM_DEPENDENCY",
  loginUrl = `${serverLocation}/login`,
  getUserRolesUrl = `${serverLocation}/userRole/getAll`,
  subject = `${serverLocation}/subject`,
  cryptoSecretKey = "techartIMagineSys@itsbest",
  URL = {
    //system configuration

    setupConfiguration: `${serverLocation}/setupConfiguration/`,
    insertSetupConfig: `${serverLocation}/setupConfiguration/insert`,
    deleteSetupConfig: `${serverLocation}/setupConfiguration/delete`,
    updateSetupConfig: `${serverLocation}/setupConfiguration/update`,

    //logout
    logout: `${serverLocation}/logMeOut`,
    //otp verify
    verifyOTP: `${serverLocation}/verifyCode`,

    // resend OTP

    resendOTP: `${serverLocation}/resendOTP`,

    //Two factor authentication

    change2FA: `${serverLocation}/toggle2FaStatus`,

    forgetPassword: `${serverLocation}/forgetPasswordRequests/insert`,
    resetViaEmail: `${serverLocation}/user/forgotPassword`,
    clearConnections: `${serverLocation}/academicYears/checkingConnections`,
    getPermissions: `${serverLocation}/getPermissions`,
    insertFlashCardSet: `${serverLocation}/flashCardSet/insert`,
    getFlashCardByClass: `${serverLocation}/flashCardSet/selectFlashCardSetByClassesSubject`,

    userProfileInfo: `${serverLocation}/user/profileInfo`,
    getCrucialProfilePeopleInfo: `${serverLocation}/user/getCrucialProfileInformation`,
    isFirstLogin: `${serverLocation}/user/isFirstLogin`,
    isUserAvailable: `${serverLocation}/user/isUserAvailable`,
    isPeopleAvailable: `${serverLocation}/user/isPeopleAvailable`,
    assignRoles: `${serverLocation}/user/userReassignRole`,

    //Primary ARR -> SubjectAreaList
    subjectAreaList: `${serverLocation}/subjectArea/`,
    insertSubjectAreaList: `${serverLocation}/subjectArea/insert`,
    deleteSubjectAreaList: `${serverLocation}/subjectArea/delete`,
    updateSubjectAreaList: `${serverLocation}/subjectArea/update`,
    getSubjectAreaListByKey: `${serverLocation}/subjectArea/selectByKey`,
    //Primary ARR -> SubjectList
    subjectList: `${serverLocation}/subject/`,
    listSubject: `${serverLocation}/subject/getSubjects`,
    insertSubjectList: `${serverLocation}/subject/insert`,
    selectByKeySubjectList: `${serverLocation}/subject/selectByKey`,
    deleteSubjectList: `${serverLocation}/subject/delete`,

    getAllAlias: `${serverLocation}/alias/`,

    getCalendarType: `${serverLocation}/calendarType/`,
    getCountry: `${serverLocation}/country/`,
    getCountryFirstLevel: `${serverLocation}/countryFirstLevel/`, // province
    getCountrySecondLevel: `${serverLocation}/countrySecondLevel/`, // district
    getCountryThirdLevel: `${serverLocation}/countryThirdLevel/`, // city

    insertSystemSetup: `${serverLocation}/systemSetup/insert`,
    checkSystemAcademicSetup: `${serverLocation}/systemSetup/status`,

    //AdmissionStatus
    getAdmissionStatus: `${serverLocation}/admissionStatus/`,
    getAdmissionStatusByKey: `${serverLocation}/admissionStatus/selectByKey`,
    getRecruitmentStatus: `${serverLocation}/recruitmentStatus/`,
    updateAdmissionStatus: `${serverLocation}/admission/update`,
    //recruitment Status
    getRecruitmentStatusByKey: `${serverLocation}/recruitmentStatus/selectByKey`,

    //studentContactInsert
    insertStudentContact: `${serverLocation}/studentContact/insert`,

    getYearLevel: `${serverLocation}/yearLevel/`,
    insertYearLevel: `${serverLocation}/yearLevel/insert`,
    getDetailYearLevel: `${serverLocation}/educationLevel/getEducationLevelYearLevelClassroomTeachers`,
    deleteDetailYearLevel: `${serverLocation}/educationLevel/deleteYearLevel`,
    reAssignYearLevel: `${serverLocation}/educationLevel/reassignYearLevelInEducationLevel`,

    selectByKeyYearLevel: `${serverLocation}/yearLevel/selectByKey`,
    updateYearLevel: `${serverLocation}/yearLevel/update`,
    deleteYearLevel: `${serverLocation}/yearLevel/delete`,

    getYearLevelByAssessmentSubjectConfig: `${serverLocation}/yearLevel/getYearLevelByAssessmentSubjectConfig`,
    // Report generator
    getYearLevelForReportGenerator: `${serverLocation}/yearLevel/getYearLevelByReportSetup`,
    downloadReport: `${serverLocation}/fileUpload/download/`,
    downloadBill: `${serverLocation}/fileUpload/download`,
    //Classrooms
    getClassroom: `${serverLocation}/classroom/`,
    selectClassroom: `${serverLocation}/classroom/selectClassrooms`,
    selectClassroomForAttendance: `${serverLocation}/classroom/selectClassroomsForAttendance`,
    getClassroomByKey: `${serverLocation}/classroom/selectByKey`,
    insertClassroom: `${serverLocation}/classroom/insert`,
    selectGeneralSubjectAssessmentFinalizedClassroom: `${serverLocation}/classroom/selectGeneralSubjectFinalizedClassrooms`,

    //update Post
    updatePersonalProfilePost: `${serverLocation}/personalProfile/updatePost`,
    updateGroupProfilePost: `${serverLocation}/groupProfile/updatePost`,
    updateFamilyProfilePost: `${serverLocation}/familyProfile/updatePost`,

    //assign students
    insertStudenttoClassroom: `${serverLocation}/studentClassroom/insert`,
    reAssignStudenttoClassroom: `${serverLocation}/studentClassroom/update`,
    getAssignedStudents: `${serverLocation}/studentClassroom/`,
    getAssignedStudentsForSearch: `${serverLocation}/studentClassroom/assigned`,
    deleteAssignedStudents: `${serverLocation}/studentClassroom/delete`,
    selectSubjectStudentClassroom: `${serverLocation}/studentClassroom/selectSubjectStudentClassroom`,

    //assessment
    insertAssessment: `${serverLocation}/assessment/insert`,
    updateAssessment: `${serverLocation}/assessment/update`,
    cancelAssessment: `${serverLocation}/assessment/delete`,
    getAssessment: `${serverLocation}/assessment/`,
    selectAssessmentByKey: `${serverLocation}/assessment/selectByKey`,
    getExamTerm: `${serverLocation}/assessment/selectAllAssessmentsByEducationLevel`,

    selectAssessments: `${serverLocation}/assessment/selectAssessments`,
    getRecordingStartedAssessment: `${serverLocation}/assessment/getRecordingStartedAssessment`,

    getAssessmentByAssessmentModule: `${serverLocation}/assessmentModule/getAssessmentByAssessmentModule`,
    getAssessmentConfig: `${serverLocation}/assessment/getGeneralAndSubjectConfig`,

    deleteClassroom: `${serverLocation}/classroom/delete`,
    updateClassroom: `${serverLocation}/classroom/update`,
    //assessment modules

    getAssessmentTypes: `${serverLocation}/assessmentType/`,
    getAssesmentModules: `${serverLocation}/assessmentModule/`,
    insertAssessmentModule: `${serverLocation}/assessmentModule/insert`,
    getUnassignedAssessmentModuleToEducationLevel: `${serverLocation}/assessmentModule/getUnassignedAssessmentModuleToEducationLevel`,
    getModulesForPermission: `${serverLocation}/module/selectForPermission`,
    insertEducationLevelAssessmentModule: `${serverLocation}/educationLevelAssessmentModule/insert`,
    getEducationLevelAssessmentModule: `${serverLocation}/educationLevelAssessmentModule/`,
    deleteEducationLevelAssessmentModule: `${serverLocation}/educationLevelAssessmentModule/delete`,
    updateEducationLevelAssessmentModule: `${serverLocation}/educationLevelAssessmentModule/update`,
    getRecruitmentIdFromEducationLevelAssessmentModule: `${serverLocation}/educationLevelAssessmentModule/selectByKey`,
    selectByKeyEducationLevelAssessmentModule: `${serverLocation}/educationLevelAssessmentModule/selectByKey`,

    //assessment Education Staff
    getAssessmentEducationStaff: `${serverLocation}/assessmentEducationStaff/`,
    insertAssessmentEducationStaff: `${serverLocation}/assessmentEducationStaff/insert`,
    deleteAssessmentEducationStaff: `${serverLocation}/assessmentEducationStaff/delete`,
    //assessment with scale or comment
    getAssessmentWithScaleOrComment: `${serverLocation}/assessment/selectAssessmentWithScaleOrComment`,
    getAssessmentWithSubjectOrGeneral: `${serverLocation}/assessment/selectAssessmentWithSubjectOrGeneral`,
    //getStaffList: `${serverLocation}/recruitment/`,
    getYearLevelByAssessmentModule: `${serverLocation}/yearLevel/getYearLevelByAssessmentModule`,
    // getUnAssignedSubjectYearLevelByAssessmentModule: `${serverLocation}/yearLevel/getUnAssignedSubjectYearLevelByAssessmentModule`,
    getUnAssignedYearLevel: `${serverLocation}/subjectYearLevel/getUnAssignedYearLevel`,
    getYearLevelByAssessment: `${serverLocation}/yearLevel/getYearLevelByAssessment`,
    getYearLevelByAssessmentForReportConfig: `${serverLocation}/yearLevel/getYearLevelByAssessmentForReportConfig`,
    getyearLevelForOptionalSubjectSetup: `${serverLocation}/yearLevel/getyearLevelForOptionalSubjectSetup`,

    getDepartment: `${serverLocation}/department/`,
    deleteDepartment: `${serverLocation}/department/delete`,
    updateDepartment: `${serverLocation}/department/update`,
    insertDepartment: `${serverLocation}/department/insert`,
    insertDepartmentStaff: `${serverLocation}/departmentStaff/insert`,

    getDesignations: `${serverLocation}/designation/`,
    insertDesignation: `${serverLocation}/designation/insert`,
    updateDesignation: `${serverLocation}/designation/update`,
    deleteDesignation: `${serverLocation}/designation/delete`,

    //Admission Form Insert
    admissionFormInsert: `${serverLocation}/admission/insert`,
    admissionFormUpdate: `${serverLocation}/admission/updateAdmission`,
    //retrive list from Admission
    admissionList: `${serverLocation}/admission/`,
    selectByKeyAdmissionList: `${serverLocation}/admission/selectByKey`,
    selectAdmissionByNonClusterFields: `${serverLocation}/admission/selectNonClusterFields`,
    selectRecruitmentByNonClusterFields: `${serverLocation}/recruitment/selectNonClusterFields`,

    searchAdmissionListByStatus: `${serverLocation}/admission/searchBy`,
    //AdmissionListUnAssigned
    selectUnAssignedStudents: `${serverLocation}/admission/unAssigned`,

    //admission import
    generateAdmissionFormat: `${serverLocation}/downloadAdmissionFormat`,
    importAdmission: `${serverLocation}/import`,

    //
    selectByKeyStudentContact: `${serverLocation}/studentContact/selectByKey`,

    //student list
    getStudentList: `${serverLocation}/admission/`,
    getStudentWithAssessmentRoutine: `${serverLocation}/admission/getStudentWithAssessmentRoutine`,
    //Student Detail Information
    getStudentDetail :`${serverLocation}/admission/getStudentDetail`,

    // Admission configuration
    getAdmissionConfigurations: `${serverLocation}/customFormConfiguration/selectFormConfigurationForAdmission`,
    //insertAdmissionConfigurations: `${serverLocation}/customFormConfiguration/selectFormConfigurationForAdmission/insert`,
    insertAdmissionConfigurations: `${serverLocation}/customFormConfiguration/insertAdmissionFormConfiguration`,
    admissionConfigurationInsert: `${serverLocation}/admission-configuration/insert`,
    getFieldTypes: `${serverLocation}/fieldType/`,
    getAddressCluster: `${serverLocation}/customTableField/selectAddressClusterFields`,
    getIdentityCluster: `${serverLocation}/customTableField/selectIdentityClusterFields`,

    // Recruitment Configuration
    getRecruitmentConfiguration: `${serverLocation}/customFormConfiguration/selectFormConfigurationForRecruitment`,
    insertRecruitmentFormConfiguration: `${serverLocation}/customFormConfiguration/insertRecruitmentFormConfiguration`,

    // Insert for recruitment form
    recruitmentFormInsert: `${serverLocation}/recruitment/insert`,
    recruitmentUpdate: `${serverLocation}/recruitment/updateRecruitment`,

    //recruitment import

    generateRecruitmentFormat: `${serverLocation}/generateRecuirtmentFormat`,
    importRecruitment: `${serverLocation}/importRecuirtment`,

    //Education Community -> Staff
    getStaffList: `${serverLocation}/recruitment/`,
    getStaffListForSubTeacherRole: `${serverLocation}/recruitment/selectSubTeachersForRole`,
    getStaffListForSubTeacherRoleList: `${serverLocation}/recruitment/selectSubTeachersForRoleList`,
    getStaffListExceptUser: `${serverLocation}/recruitment/selectStaffsExceptUser`,
    searchRecruitmentListByStatus: `${serverLocation}/recruitment/searchBy`,
    updateStaff: `${serverLocation}/recruitment/update`,
    selectRecruitmentByPeopleId: `${serverLocation}/recruitment/selectByPeopleId`,
    selectByKeyRecruitmentList: `${serverLocation}/recruitment/selectByKey`,

    //educationLevel
    weekDays: `${serverLocation}/weekDays/`,
    insertEduLevel: `${serverLocation}/educationLevel/insert`,
    getEducationLevel: `${serverLocation}/educationLevel/`,
    getEducationLevelSelectAll: `${serverLocation}/educationLevel/selectAll`,
    getEducationLevelUnassignedAssessmentModule: `${serverLocation}/educationLevel/unassignedAssessmentModule`,
    getEducationLevelByAssessmentModule: `${serverLocation}/assessmentModule/selectEducationLevelByAssessmentModule`,
    getEducationLevelByKey: `${serverLocation}/educationLevel/selectByKey`,
    updateEduLevel: `${serverLocation}/educationLevel/update`,
    getTempEducationLevel: `${serverLocation}/tempEducationLevel/`,
    getWeekDaysByEducationLevel: `${serverLocation}/weekDays/selectWeekDayByEducationLevelDivisionAcademicYearWise`,
    getWeekDaysByEducationLevelDivision: `${serverLocation}/weekDays/selectWeekDayByEducationLevelDivision`,

    //Academic Year
    getAcademicYears: `${serverLocation}/academicYears/`,
    getAllAcademicYears: `${serverLocation}/academicYears/getAll`,
    insertAcademicYears: `${serverLocation}/academicYears/insert`,
    insertNewAcademicYears: `${serverLocation}/academicYears/newAcademicYear`,
    updateAcademicYearsIsCurrent: `${serverLocation}/academicYears/updateIsCurrent`,
    updateAcademicYearsIsEnabled: `${serverLocation}/academicYears/updateIsEnabled`,
    getAcademicYearByKey: `${serverLocation}/academicYears/selectByKey`,
    selectCurrentAcademicYear: `${serverLocation}/academicYears/selectCurrentAcademicYear`,

    switchUserAcademicYear: `${serverLocation}/userAcademicYear/history`,
    selectUserAcademicYear: `${serverLocation}/userAcademicYear/`,
    deleteUserAcademicYear: `${serverLocation}/userAcademicYear/delete`,
    getCoreAcademicYears:`${serverLocation}/academicYears/getCoreAcademicYears`,

    //Education Level Division
    getEducationLevelDivisions: `${serverLocation}/educationLevel/getEducationLevelDivision`,
    getEducationLevelDivisionsByEducationLevelId: `${serverLocation}/educationLevelDivision/`,
    getEducationLevelDivisionByKey: `${serverLocation}/educationLevelDivision/selectByKey`,
    getYearLevelAssignedToEducationLevel: `${serverLocation}/educationLevel/getAssignedYearLevel`,
    insertEducationLevelDivision: `${serverLocation}/educationLevelDivision/insert`,
    deleteEducationLevelDivision: `${serverLocation}/educationLevelDivision/delete`,
    updateEducationLevelDivision: `${serverLocation}/educationLevelDivision/update`,

    //Card and Certificate
    getAllIdCard: `${serverLocation}/idCard/getIDCardConfig`,

    //staffType
    getStaffType: `${serverLocation}/staffType/`,
    //calendar
    getEvents: `${serverLocation}/calendarEvent/`,
    getEventsCore: `${serverLocation}/calendarEvent/selectCore`,
    getEventTypes: `${serverLocation}/calendarEvent/getCalendarEventType`,
    deleteEventType: `${serverLocation}/calendarEvent/deleteEventType`,
    insertEventType: `${serverLocation}/calendarEvent/insertEventType`,
    getEventsByEducationLevel: `${serverLocation}/calendarEvent/selectByKey`,
    insertEvent: `${serverLocation}/calendarEvent/insert`,
    updateEvent: `${serverLocation}/calendarEvent/update`,
    deleteEvent: `${serverLocation}/calendarEvent/delete`,
    getWeekDays: `${serverLocation}/weekDays/`,
    getWeekDayByEducationLevelDivisionAcademicYearWise: `${serverLocation}/weekDays/selectWeekDayByEducationLevelDivisionAcademicYearWise`,
    getWeekDaysByEduDiv: `${serverLocation}/weekDays/selectWeekDayByEducationLevelDivision`,
    insertWeekDays: `${serverLocation}/weekDays/insert`,
    getEventsById: `${serverLocation}/calendarEvent/selectById`,
    getHolidayOptions: `${serverLocation}/calendarEvent/getCalendarEventType`,
    insertCalenderEventType: `${serverLocation}/calendarEvent/insertCalendarEventType`,
    deleteCalendarEventType: `${serverLocation}/calendarEvent/deleteCalendarEventType`,
    updateCalendarEventType: `${serverLocation}/calendarEvent/updateCalendarEventType`,

    //calendar event type core

    insertCalenderEventTypeCore: `${serverLocation}/calendarEvent/insertEventType`,
    updateCalenderEventType: `${serverLocation}/calendarEvent/updateCalendarEventType`,

    //yearlevels
    getYearLevelByAssessmentModules: `${serverLocation}/yearLevel/getYearLevelByAssessmentModule`,
    getUnassignedYearLevels: `${serverLocation}/yearLevel/getUnAssignedYearLevel`,
    assignYearLevel: `${serverLocation}/educationLevel/assignYearLevel`,
    getYearLevelByEducationLevel: `${serverLocation}/yearLevel/getYearLevelByEducationLevel`,
    getYearLevelByPreviousAssessment: `${serverLocation}/yearLevel/getYearLevelByPreviousAssessment`,
    // getUnAssignedYearLevel: `${serverLocation}/yearLevel/getUnAssignedYearLevel`,
    unAssignYearLevelFromEducationLevel: `${serverLocation}/educationLevel/unassignYearLevel`,

    //subjects
    getSubjectsByYearLevel: `${serverLocation}/subjectYearLevel/`,
    deleteSubjectSetting: `${serverLocation}/subjectYearLevel/delete`,
    getStudentSubjectsByYearlLevel: `${serverLocation}/subjectYearLevel/getSubjects`,
    selectSystemExamSubjects: `${serverLocation}/subject/selectSystemExamSubjects`,
    insertSystemExamSubjectQuestions: `${serverLocation}/subjectQuestion/insert`,
    selectDistinctSubjectsByYearLevel: `${serverLocation}/subject/selectDistinctSubjectsByYearLevel`,
    selectDistinctSubjectsByYearLevelCore: `${serverLocation}/subject/selectDistinctSubjectsByYearLevelCore`,
    selectDistinctSubjectsByYearLevelCoreForAcademic: `${serverLocation}/subject/selectDistinctSubjectsByYearLevelCoreForAcademic`,

    //get subject settings
    getSubjectSettings: `${serverLocation}/subjectSettings/`,

    //get Subject Teacher
    getSubjectTeachers: `${serverLocation}/subjectClassroomTeacher/getSubjectTeachers`,

    //getSubjects with scale or comment
    getSubjectWithScaleOrComment: `${serverLocation}/subject/selectSubjectsWithScaleOrComment`,

    //assignSubjectTeacher
    getSubjectClassroomTeacher: `${serverLocation}/subjectYearLevel/selectYearLevelBySubject`,
    insertSubjectClassroomTeacher: `${serverLocation}/subjectClassroomTeacher/insert`,
    deleteSubjectClassroomTeacher: `${serverLocation}/subjectClassroomTeacher/delete`,
    getSubjectByClassroom: `${serverLocation}/subjectClassroomTeacher/getSubjectByClassroom`,

    //schoolInfo
    getSchoolInfo: `${serverLocation}/school/`,
    updateSchoolInfo: `${serverLocation}/school/update`,
    selectByKeySchool: `${serverLocation}/school/selectByKey`,
    getSchoolChartData: `${serverLocation}/school/getSchoolUserStat`,
    selectSchoolInfo: `${serverLocation}/school/selectSchoolInfo`,

    getSchoolByKey: `${serverLocation}/school/selectByKey`,

    //school import

    importSchool: `${serverLocation}/importSchools`,
    searchSchool: `${serverLocation}/school/search`,

    //classROutine
    insertClassRoutine: `${serverLocation}/classRoutine/insert`,
    getClassRoutine: `${serverLocation}/classRoutine/selectByKey`,
    deleteClassRoutine: `${serverLocation}/classRoutine/delete`,
    updateClassRoutine: `${serverLocation}/classRoutine/update`,

    //examRoutine
    getExamAllRoutine: `${serverLocation}/examRoutine/selectAssessmentGroupByYearLevel`,

    //Groups
    getGroups: `${serverLocation}/group/verticalGroup`,
    getHorizontalGroup: `${serverLocation}/group/horizontalGroup`,
    getGroupSelectByKey: `${serverLocation}/group/selectByKey`,
    getGroupAssociatedPeople: `${serverLocation}/group/getAssociatedGroupPeople`,
    updateCoreGroups: `${serverLocation}/group/updateCore`,

    //generateAssociateLink
    getAssociatedLink: `${serverLocation}/linkAssociate`,

    insertGroup: `${serverLocation}/group/insert`,
    insertGroupCore: `${serverLocation}/group/insertCore`,

    deleteGroup: `${serverLocation}/group/deleteGroup`,
    updateGroup: `${serverLocation}/group/update`,
    getGroupRoles: `${serverLocation}/group/getGroupRole`,
    assignMembertoGroup: `${serverLocation}/group/groupAssignRoleAndMember`,
    updateGroupRole: `${serverLocation}/group/updateMemberRole`,
    deleteGroupMember: `${serverLocation}/group/deleteGroupMember`,
    // searchPeopleInGroup: `${serverLocation}/group/searchGroupPeople`,
    getUnassignedstudentInGroup: `${serverLocation}/group/getUnassignedstudentInGroup`,
    getUnassignedStaffInGroup: `${serverLocation}/group/getUnassignedStaffInGroup`,
    getAllGroups: `${serverLocation}/group/`,
    getAllGroupsCentral: `${serverLocation}/group/selectCore`,

    getCentralLoggeduserGroup: `${serverLocation}/group/getCentralLoggeduserGroup`,
    getGroupByName: `${serverLocation}/group/selectByName`,
    checkGroupAccess: `${serverLocation}/group/checkGroupAccess`,
    selectUserDefaultGroup: `${serverLocation}/group/selectUserDefaultGroup`,
    getFamilyGroup: `${serverLocation}/group/familyGroup`,

    //building
    buildingSelect: `${serverLocation}/building/`,
    buildingInsert: `${serverLocation}/building/insert`,
    buildingSelectByKey: `${serverLocation}/building/selectByKey`,
    buildingUpdate: `${serverLocation}/building/update`,
    buildingDelete: `${serverLocation}/building/delete`,
    //floorSection
    floorSelect: `${serverLocation}/floor/`,
    floorDelete: `${serverLocation}/floor/delete`,
    floorSelectByKey: `${serverLocation}/floor/selectByKey`,
    floorUpdate: `${serverLocation}/floor/update`,
    floorSectionRoom: `${serverLocation}/floor/selectFloorSectionRoom`,

    //subjectSetting
    insertSubjectSetting: `${serverLocation}/subjectYearLevel/newInsert`,
    getSubjectSetting: `${serverLocation}/subjectYearLevel/`,

    //Building Section
    buildingSectionSelect: `${serverLocation}/buildingSection/`,
    buildingSectionInsert: `${serverLocation}/buildingSection/insert`,
    buildingSectionDelete: `${serverLocation}/buildingSection/delete`,
    buildingSectionUpdate: `${serverLocation}/buildingSection/update`,
    buildingSectionSelectByKey: `${serverLocation}/buildingSection/selectByKey`,

    //RoomSection
    roomSelect: `${serverLocation}/room/`,
    unAssignedRoom: `${serverLocation}/room/getUnassignedRoomInClassroom`,
    assignedRoom: `${serverLocation}/room/getAssignedRoomInClassroom`,
    roomInsert: `${serverLocation}/room/insert`,
    roomFloorSelect: `${serverLocation}/building/selectFloorRoom`,
    roomDelete: `${serverLocation}/room/delete`,
    roomSelectByKey: `${serverLocation}/room/selectByKey`,
    roomUpdate: `${serverLocation}/room/update`,

    //DefaultGpa value get api
    gpaSelect: `${serverLocation}/gpa/`,

    // Assessment Scale
    selectAssessmentScale: `${serverLocation}/assessmentScale/`,
    getSubjectListForSubjectAssessmentSetting: `${serverLocation}/assessment/getSubjectListForSubjectAssessmentSetting`,
    insertAssessmentSubjectScaleConfiguration: `${serverLocation}/assessmentSubjectScaleConfiguration/insert`,
    selectAssessmentSubjectScaleConfigGroups: `${serverLocation}/assessmentSubjectScaleConfiguration/selectAssessmentSubjectScaleConfigGroups`,
    selectAssessmentSubjectScaleConfigGroupsForSubjectAssessmentConfig: `${serverLocation}/assessmentSubjectScaleConfiguration/selectAssessmentSubjectScaleConfigGroupsForSubjectAssessmentConfig`,
    selectSubjectAssessmentScaleByAssessment: `${serverLocation}/assessmentScale/selectSubjectAssessmentScaleByAssessment`,
    selectAssessmentScaleByCode: `${serverLocation}/assessmentScale/`,
    deleteAssessmentScale: `${serverLocation}/assessmentScale/delete`,
    updateAssessmentScale: `${serverLocation}/assessmentScale/update`,
    updateAssessmentSubjectScaleConfiguration: `${serverLocation}/assessmentSubjectScaleConfiguration/update`,

    //General Assessment
    generalAssessmentInsert: `${serverLocation}/assessmentGeneralConfiguration/insert`,
    generalAssessmentSelect: `${serverLocation}/assessmentGeneralConfiguration/selectAssessmentGeneralConfiguration`,
    generalAssessmentSelectAll: `${serverLocation}/assessmentGeneralConfiguration/selectYearLevelByAssessmentGeneralConfiguration`,
    generalAssessmentSelectByYearAndClass: `${serverLocation}/assessmentGeneralConfiguration/selectAssessmentWithAssessmentGeneralConfiguration`,
    selectClassroomList: `${serverLocation}/assessmentGeneralConfiguration/getYearLevelClassroomByAssessmentWithFilteringSelect`,
    generalAssessmentConfigUpdate: `${serverLocation}/assessmentGeneralConfiguration/update`,

    // Student subject assessment
    selectAssessmentScaleForSubject: `${serverLocation}/assessmentSubjectScaleConfiguration/selectAssessmentScaleForSubject`,
    SelectStudentSubjectAssessment: `${serverLocation}/studentSubjectAssessment/`,
    insertStudentSubjectAssessment: `${serverLocation}/studentSubjectAssessment/insert`,
    insertMultipleStudentSubjectAssessments: `${serverLocation}/studentSubjectAssessment/insertMultiple`,
    selectStudentSubjectAssessmentByKey: `${serverLocation}/studentSubjectAssessment/selectByKey`,
    updateStudentSubjectAssessmentIsFinalized: `${serverLocation}/studentSubjectAssessment/updateIsFinalized`,
    selectStudentSubjectAssessmentIsFinalized: `${serverLocation}/studentSubjectAssessment/selectIsFinalized`,
    selectStudentSubjectAssessmentFinalizeClickable: `${serverLocation}/studentSubjectAssessment/selectFinalizeClickable`,
    getYearLevelForStudentSubjectAssessment: `${serverLocation}/yearLevel/getYearLevelByTeacherSubjectAssessment`,
    selectClassroomsWithSubjectAsssessment: `${serverLocation}/classroom/selectClassroomsWithSubjectAsssessment`,
    selectAssessmentBySubjectAsssessment: `${serverLocation}/assessment/selectAssessmentBySubjectAsssessment`,
    selectSubjectByLoggedStaff: `${serverLocation}/subject/getSubjectByLoggedStaff`,

    // AssessmentSubjectCommentConfiguration
    selectAssessmentSubjectCommentConfigurationByKey: `${serverLocation}/assessmentSubjectCommentConfiguration/selectByKey`,

    selectClassroomForSubjectAssessmentSetting: `${serverLocation}/assessmentSubjectConfiguration/getYearLevelClassroomBySubjectAssessmentWithFilteringSelect`,
    selectYearLevelClassroomBySubjectAssessmentBySubject: `${serverLocation}/assessmentSubjectConfiguration/getYearLevelClassroomBySubjectAssessmentBySubject`,

    //CommentBank
    insertCommentBank: `${serverLocation}/commentBank/insert`,
    getCommentBanks: `${serverLocation}/commentBank/`,
    deleteCommentBank: `${serverLocation}/commentBank/delete`,
    updateCommentBank: `${serverLocation}/commentBank/update`,
    //student General Assessment
    studentGeneralAssessmentInsert: `${serverLocation}/studentGeneralAssessment/insert`,
    studentGeneralAssessmentFinalize: `${serverLocation}/studentGeneralAssessment/updateIsFinalized`,
    selectGeneralAssessmentIsFinalized: `${serverLocation}/studentGeneralAssessment/selectIsFinalized`,
    selectFinalizeClickable: `${serverLocation}/studentGeneralAssessment/finalizeClickable`,

    // assessmentGeneralCustomAssessmentConfiguration
    selectGeneralCustomAssessmentConfigurations: `${serverLocation}/assessmentGeneralCustomAssessmentConfiguration/selectGeneralCustomAssessmentConfigurations`,

    //getLegends
    legendsForReportingSetup: `${serverLocation}/assessmentScale/legendsForReportingSetup`,
    //getGeneral Comment for Chip in reporting setup
    commentChipForReportingSetup: `${serverLocation}/assessmentGeneralCommentConfiguration/getByAssessmentYearlevel`,

    //get Previous assessment
    selectPreviousAssessment: `${serverLocation}/assessment/selectPreviousAssessment`,
    //student Name value pair
    studentNameValuePair: `${serverLocation}/commentBank/getTagsValueForStudent`,
    uploadUrl: `${serverLocation}/fileUpload/uploadFile`,

    //profile
    getProfilePicture: `${serverLocation}/profilePicture/select`,
    insertProfilePicture: `${serverLocation}/profilePicture/insert`,
    removeExistingProfilePicture: `${serverLocation}/profilePicture/removeProfilePicture`,
    selectProfilePictureNoticeById: `${serverLocation}/profilePicture/selectByPostId`,
    acceptRejectPP: `${serverLocation}/profilePicture/handleModeration`,

    //profile core
    updateProfileCore: `${serverLocation}/user/userUpdate`,
    updateProfileImgCore: `${serverLocation}/user/userProfileUpdate`,

    //coverPicture
    insertCoverPictures: `${serverLocation}/coverPicture/insert`,
    insertSystemDefaultPictures: `${serverLocation}/systemDefaultPicture/insert`,
    getSystemDefaultImage: `${serverLocation}/systemDefaultPicture/select`,
    getCoverPictures: `${serverLocation}/coverPicture/select`,
    deleteCoverPicture: `${serverLocation}/coverPicture/delete`,
    deleteSystemDefaultImage: `${serverLocation}/systemDefaultPicture/delete`,
    coverPictureMultipleUpdate: `${serverLocation}/coverPicture/updateMultiple`,
    selectCoverPictureNoticeById: `${serverLocation}/coverPicture/selectByPostId`,
    acceptRejectCP: `${serverLocation}/coverPicture/handleModeration`,

    //FamilyCoverPicture
    insertFamilyCoverPictures: `${serverLocation}/familyCoverPicture/insert`,
    getFamilyCoverPictures: `${serverLocation}/familyCoverPicture/select`,
    deleteFamilyCoverPictures: `${serverLocation}/familyCoverPicture/delete`,
    updateFamilyCoverPictures: `${serverLocation}/familyCoverPicture/update`,
    updateMultipleFamilyCoverPictures: `${serverLocation}/familyCoverPicture/updateMultiple`,
    checkIsLegal: `${serverLocation}/studentContact/isLegalGuardian`,

    //GroupCoverPicture

    insertGroupCoverPictures: `${serverLocation}/groupCoverPicture/insert`,
    getGroupCoverPictures: `${serverLocation}/groupCoverPicture/select`,
    deleteGroupCoverPictures: `${serverLocation}/groupCoverPicture/delete`,
    updateGroupCoverPictures: `${serverLocation}/groupCoverPicture/update`,
    checkGroupAdminModerator: `${serverLocation}/group/groupCoverEditable`,
    updateMultipleGroupCoverPictures: `${serverLocation}/groupCoverPicture/updateMultiple`,

    //POST SECTION
    insertPost: `${serverLocation}/post/insert`,
    insertPostContent: `${serverLocation}/postContent/insert`,
    updatePost: `${serverLocation}/post/update`,
    updatePostNotice: `${serverLocation}/post/updatePostNotice`,
    deletePost: `${serverLocation}/post/delete`,
    getPostHistory: `${serverLocation}/post/getPostHistory`,

    updatePostCore: `${serverLocation}/post/discussionUpdate`,

    //Assignment
    getClassForAssignment: `${serverLocation}/subjectClassroomTeacher/getLoggedInUserYearClassSubject`,
    insertAssignment: `${serverLocation}/assignment/insert`,
    selectAssignment: `${serverLocation}/assignment/`,
    selectAssignmentStudents: `${serverLocation}/assignmentStudent/`,
    selectAssignmentStudentsByFamilyId: `${serverLocation}/assignmentStudent/selectAssignmentStudentsByFamilyId`,
    getStudentAssigmentStatistics: `${serverLocation}/assignment/studentAssigmentStatistics`,

    updateAssignmentStudentStatus: `${serverLocation}/assignmentStudent/update`,
    getAssignmentByPostId: `${serverLocation}/assignment/getByPostId`,
    deleteAssignment: `${serverLocation}/assignment/delete`,
    assignAssignment: `${serverLocation}/assignment/assignAssignment`,
    updateAssignment: `${serverLocation}/assignment/update`,

    //Assignment Resource
    deleteAssignmentResources: `${serverLocation}/assignmentResources/delete`,

    submitStudentAssignment: `${serverLocation}/studentAssignmentResources/insert`,
    viewStudentAssignmentResources: `${serverLocation}/studentAssignmentResources/`,

    viewAssignment: `${serverLocation}/assignmentStudent/updateIsSeen`,
    selectAssignmentByKey: `${serverLocation}/assignment/selectByKey`,

    //GROUP POST SECTION
    insertGroupPost: `${serverLocation}/groupProfile/insert`,
    selectGroupPost: `${serverLocation}/groupProfile/`,

    //Assessment Record Report
    selectAssessmentScales: `${serverLocation}/assessmentScaleType/`,

    //level insert
    insertAssessmentScaleLevel: `${serverLocation}/assessmentScale/insert`,
    selectAssessmentScaleLevel: `${serverLocation}/assessmentScale/selectAssessmentScaleLevelByAssessmentScale`,
    insertAssessmentScaleLevelImage: `${serverLocation}/assessmentScale/insertLevelWithImage`,

    //POST - NOTICE
    getNotice: `${serverLocation}/post/selectNotice`,
    searchNotice: `${serverLocation}/post/search`,
    getPostByReferenceId: `${serverLocation}/post/selectByReferenceId`,
    downloadFile: `${serverLocation}/downloadFile`,
    getPersonAcademicRepo: `${serverLocation}/post/academicRepo`,
    getPersonalNotice: `${serverLocation}/post/selectNotice`,
    selectPostNoticeById: `${serverLocation}/post/selectByPostId`,
    acceptRejectPost: `${serverLocation}/post/handleModeration`,
    // downloadAttachedFiles: `${serverLocation}/fileUpload/download/`,

    //POST-NOTICE SECTION
    getGroupNotices: `${serverLocation}/post/selectGroupPageNotice`,
    getFamilyNotices: `${serverLocation}/post/selectFamilyPageNotice`,

    //AcademicRepository
    getacademicRepositoryPost: `${serverLocation}/academicRepository/`,
    getAcademicRepoByRefId: `${serverLocation}/academicRepository/selectByReferenceId`,
    searchAcademicRepo: `${serverLocation}/academicRepository/search`,
    updateAcademicRepo: `${serverLocation}/academicRepository/update`,

    //POST - ACKNOWLEDGED
    getAcknowledgeStatus: `${serverLocation}/postAudience/selectByKey`,
    setAcknowledgeStatus: `${serverLocation}/postAudience/update`,
    setRemindStatus: `${serverLocation}/postAudience/setRemindStatus`,
    selectGroupPeople: `${serverLocation}/postAudience/select`,
    searchPostAudience: `${serverLocation}/postAudience/search`,

    //POST - COMMENT
    getCommentByKey: `${serverLocation}/postComment/selectByKey`,
    postComment: `${serverLocation}/postComment/insert`,
    deleteComment: `${serverLocation}/postComment/delete`,
    updateComment: `${serverLocation}/postComment/update`,
    getCommentByPost: `${serverLocation}/postComment/selectByPostId`,

    //Academic Repo Comment
    insertAcademicRepoComment: `${serverLocation}/academicRepoComment/insert`,
    updateAcademicRepoComment: `${serverLocation}/academicRepoComment/update`,
    deleteAcademicRepoComment: `${serverLocation}/academicRepoComment/delete`,
    getAcademicRepoComment: `${serverLocation}/academicRepoComment/selectByKey`,

    getAcademicRepoCommentByPost: `${serverLocation}/academicRepoComment/selectByPostId`,

    //handleCommentMOderation
    handleCommentModeartion: `${serverLocation}/postComment/handleModeration`,
    //AcademicPost Comment Moderation
    getCommentByAcademicPost: `${serverLocation}/academicRepoComment/selectByPostId`,
    handleAcademicPostCommentModeration: `${serverLocation}/academicRepoComment/handleModeration`,
    handleAcademicRepoCommentModeration: `${serverLocation}/academicRepoComment/handleModeration`,

    // Report Setup - Step 1,2,3
    insertReportSetup: `${serverLocation}/reportSetup/insert`,
    updateReportSetup: `${serverLocation}/reportSetup/update`,
    selectReportSetups: `${serverLocation}/reportSetup/`,
    selectByReportSetupYearLevel: `${serverLocation}/reportSetup/selectByReportSetupYearLevel`,
    generateReport: `${serverLocation}/reportSetup/generateHtml`,
    getReportSetupByAssessment: `${serverLocation}/reportSetup/selectGroupedByAssessment`,
    publishReport: `${serverLocation}/reportSetup/publishpdf`,
    //Add Theme
    insertTheme: `${serverLocation}/theme/insert`,
    selectTheme: `${serverLocation}/theme/`,

    //StudentAttendance
    getStudentsAttendance: `${serverLocation}/studentAttendance/`,
    getAttendanceByDayMonthYear: `${serverLocation}/studentAttendance/selectByDayMonthYear`,
    getAttendaceForSummary: `${serverLocation}/studentAttendance/selectForSummary`,
    getAttendanceByDayMonthYearForParents: `${serverLocation}/studentAttendance/selectByDayMonthYearForParents`,
    insertStudentAttendance: `${serverLocation}/studentAttendance/insert`,
    videoConfrenceStudentsAttendance: `${serverLocation}/studentAttendance/videoConferenceAttendance`,
    getTotalStudents: `${serverLocation}/admission/countTotalStudents`,
    getTotalStudentsAssignedToSomeClassroom: `${serverLocation}/studentClassroom/countTotalStudents`,
    getEnglishMonth: `${serverLocation}/month/getEnglishMonth`,
    getAdmissionDetailSummary: ` ${serverLocation}/studentAttendance/getAdmissionDetailSummary`,

    //mail Config
    getEmailConfiguration: `${serverLocation}/emailConfiguration/select`,
    insertEmailConfiguration: `${serverLocation}/emailConfiguration/insert`,
    deleteEmailConfiguration: `${serverLocation}/emailConfiguration/delete`,
    //Personal Profile
    insertPersonProfilePost: `${serverLocation}/personalProfile/insert`,

    selectPersonProfilePosts: `${serverLocation}/personalProfile/`,

    //Recent Posts
    getPersonProfileRecentPosts: `${serverLocation}/personalProfile/recentPost`,

    //Search Posts
    searchPersonalPost: `${serverLocation}/personalProfile/postSearch`,
    searchGroupPost: `${serverLocation}/groupProfile/searchGroupPost`,
    searchFamilyPost: `${serverLocation}/familyProfile/searchFamilyPost`,

    //Family Profile
    insertFamilyProfile: `${serverLocation}/familyProfile/insert`,
    selectFamilyProfile: `${serverLocation}/familyProfile/`,
    // Report generator
    // getYearLevelForReportGenerator: `${serverLocation}/yearLevel/getYearLevelByReportSetup` -- removed from here and added with other year level urls.

    //permission
    // getUserRoles: `${serverLocation}/userRole/`,
    getUserRoleNoAuth: `${serverLocation}/userRole/getAll`,
    getAllModules: `${serverLocation}/module/`,
    getAllCoreModules: `${serverLocation}/module/selectCore`,
    insertModule: `${serverLocation}/module/insert`,
    deleteModules: `${serverLocation}/module/delete`,
    getUserSubRoles: `${serverLocation}/userSubRole/`,
    insertUserSubRoles: `${serverLocation}/userSubRole/insert`,
    getUserSubRolesBySubRoleCode: `${serverLocation}/userSubRole/selectBySubRoleId`,
    insertAllUserSubRoles: `${serverLocation}/userSubRole/insertAll`,
    getSubRolesByUserRole: `${serverLocation}/subRole/`,
    getAllActivity: `${serverLocation}/activity/`,
    insertPermissionConfiguration: `${serverLocation}/permission/insert`,
    getPermissionList: `${serverLocation}/permission/`,
    selectUserPermissionByKey: `${serverLocation}/userPermission/selectUserPermissionByKey`,

    updatePermissionConfiguration: `${serverLocation}/permission/update`,
    deletePermissionConfiguration: `${serverLocation}/permission/delete`,
    insertAllPermissionConfiguration: `${serverLocation}/permission/insertAll`,

    // get users subrole
    getUserSubRoleData: `${serverLocation}/user/getAssignedUserSubRoleByUser`,
    reassignUserSubRole: `${serverLocation}/user/reassignUserSubRole`,

    //familyInformation
    getFamilyName: `${serverLocation}/familyMember/familyInfo`,
    selectFamilyMembers: `${serverLocation}/familyMember/members`,

    //assessmentScalebygroup fro report configuration
    selectAssessmentScaleByGroup: `${serverLocation}/assessmentScale/assessmentScaleByGroup`,

    //password
    updatePassword: `${serverLocation}/user/updatePassword`,
    getLoggedInUser: `${serverLocation}/user/getloggedinUser`,

    // updatePasswordViaEmail: `${serverLocation}/user/forgotPasswordUpdate`,

    //
    getAssesmentModuleAndEducationLevelForCurrentUser: `${serverLocation}/educationLevelAssessmentModule/selectByCurrentUser`,
    getEducationLevelForCurrentUser: `${serverLocation}/educationLevel/selectByCurrentUser`,

    //studentClassroomOptionalSubject
    insertStudentClassroomOptionalSubject: `${serverLocation}/studentClassroomOptionalSubject/insert`,
    selectStudentClassroomOptionalSubject: `${serverLocation}/studentClassroomOptionalSubject/`,
    deleteStudentClassroomOptionalSubject: `${serverLocation}/studentClassroomOptionalSubject/delete`,

    //getYearLevelByTeacher
    selectYearLevelByTeacher: `${serverLocation}/yearLevel/getYearLevelByTeacher`,

    //widget
    getWidgets: `${serverLocation}/widget/`,
    //widgetModuels
    getWidgetModules: `${serverLocation}/widgetModule/`,
    //module
    getModules: `${serverLocation}/module/`,
    //widgetModule
    insertWidgetModules: `${serverLocation}/widgetModule/insert`,
    //roles
    getUserRoles: `${serverLocation}/userRole/`,
    //userSubRole
    insertSubRole: `${serverLocation}/subRole/insert`,

    //userlist by rolecode
    getUserByRoleCode: `${serverLocation}/user/selectUsersByRoleCode`,
    getUsersByRoleCodeForPermission: `${serverLocation}/user/selectUsersByRoleCodeForPermission`,
    getUsersById: `${serverLocation}/user/selectUsersById`,

    generateUserImportFormat: `${serverLocation}/generateUserFormat`,

    importUserData: `${serverLocation}/userImport`,

    //insert user permission
    insertUserPermission: `${serverLocation}/userPermission/insert`,
    getUserPermissionByWidgetModuleActivity: `${serverLocation}/userPermission/selectUserPermissionByWidgetModuleActivity`,
    //subRoles
    getSubRoles: `${serverLocation}/subRole/`,

    //getYearLevelForFinalizedAssessment
    selectYearLevelForFinalizedAssessment: `${serverLocation}/yearLevel/getYearLevelForFinalizedAssessment`,

    //getFinalizedAssessments
    selectFinalizedGeneralAndSubjectAssessment: `${serverLocation}/assessment/selectFinalizedGeneralSubjectAssessment`,

    //selectFinalizedAssessmentYearLevel
    selectFinalizedAssessmentYearLevel: `${serverLocation}/assessment/selectFinalizedAssessmentYearLevel`,

    //generalAssessmentAccessorRole
    selectGeneralAssessmentAccessorRole: `${serverLocation}/generalAssessmentAccessorRole/`,
    //gender
    getGenders: `${serverLocation}/gender/`,
    insertGenders: `${serverLocation}/gender/insert`,

    //relationship
    getRelationships: `${serverLocation}/relationships/`,
    insertRelationship: `${serverLocation}/relationships/insert`,

    //image select
    imageSelectURL: `${serverLocation}/xvzfyhojcdeQnrIvAZVmgf3Sb1AoNmfA.ZQz851AchimYgyRW8.rgSFIdxjur80Wefgjkhskjhdnkdslkdji/`,

    //file download
    fileDownloadURL: `${serverLocation}/fileUpload/download/`,

    //mcq
    insertMcq: `${serverLocation}/mcqTest/insert`,
    getMcqList: `${serverLocation}/mcqTest/`,
    getMcqById: `${serverLocation}/mcqTest/selectByKey`,
    getMcqByPostId: `${serverLocation}/mcqTest/selectByPostId`,
    getMCQDataForUpdate: `${serverLocation}/mcqTest/selectAllData`,
    deleteMcq: `${serverLocation}/mcqTest/delete`,
    updateMcq: `${serverLocation}/mcqTest/update`,
    getMCQBySubject: `${serverLocation}/mcqTest/selectMcqByClassesSubject`,

    getTestType: `${serverLocation}/testType/`,

    insertMcqOption: `${serverLocation}/peopleMcq/insert`,
    getAllStudentMCQResultList: `${serverLocation}/peopleMcq/getList`,
    getStudentMCQResultList: `${serverLocation}/peopleMcq/getResult`,

    //video conference
    videoConferencInsert: `${serverLocation}/videoConference/insert`,
    videoConferenceSelectByPostId: `${serverLocation}/videoConference/selectByPostId`,
    videoConferenceUpdate: `${serverLocation}/videoConference/update`,

    //manageuser
    createUser: `${serverLocation}/user/createUser`,

    //ForgetPassword
    getForgetPasswordRequestUsers: `${serverLocation}/forgetPasswordRequests/`,
    rejectPassword: `${serverLocation}/forgetPasswordRequests/reject`,
    updateForgetPassword: `${serverLocation}/user/updateForgetPassword`,

    // users
    getAllUsersCore: `${serverLocation}/user/selectCore`,
    getAllUsers: `${serverLocation}/user/getAllUsers`,
    getAllCombinedUsers: `${serverLocation}/user/getAllCombinedUsers`,

    enableDisableUser: `${serverLocation}/user/enableDisableUser`,

    //Flash Cards
    insertFlashCards: `${serverLocation}/flashCardSet/insert`,
    getPublicFlashCards: `${serverLocation}/flashCardSet/publicFlashCardSet`,
    getMyFlashCards: `${serverLocation}/flashCardSet/myFlashCardSet`,
    deleteFlashCardSet: `${serverLocation}/flashCardSet/delete`,
    updateFlashCardSet: `${serverLocation}/flashCardSet/update`,
    deleteFlashCards: `${serverLocation}/flashCardSet/deleteFlashCard`,
    updateFlashCards: `${serverLocation}/flashCardSet/updateFlashCard`,

    //Learning Management System
    getCourseTermFormat: `${serverLocation}/courseTermFormat/`,
    insertCourse: `${serverLocation}/course/insert`,
    getCourse: `${serverLocation}/course/`,
    updateCourse: `${serverLocation}/course/update`,
    deleteCourse: `${serverLocation}/course/delete`,
    getProgramList: `${serverLocation}/course/selectList`,
    getProgramSubjects: `${serverLocation}/course/selectByKey`,
    getProgramAdmissionList: `${serverLocation}/course/getAdmissionByCourse`,
    assignUnassignCourseAdmission: `${serverLocation}/course/assignOrunassign`,

    //coursePhase
    insertCoursePhase: `${serverLocation}/coursePhase/insert`,
    getCoursePhase: `${serverLocation}/coursePhase/`,
    updateCoursePhase: `${serverLocation}/coursePhase/update`,
    deleteCoursePhase: `${serverLocation}/coursePhase/delete`,

    //courseTerm
    insertCourseTerm: `${serverLocation}/courseTerm/insert`,
    getCourseTerm: `${serverLocation}/courseTerm/`,
    updateCourseTerm: `${serverLocation}/courseTerm/update`,
    deleteCourseTerm: `${serverLocation}/courseTerm/delete`,

    //courseSubject
    insertCourseSubject: `${serverLocation}/courseSubject/insert`,
    getCourseSubject: `${serverLocation}/courseSubject/`,
    updateCourseSubject: `${serverLocation}/courseSubject/update`,
    deleteCourseSubject: `${serverLocation}/courseSubject/delete`,

    //link Course Phase and Course Term
    assignCoursePhaseTerm: `${serverLocation}/courseTermPhase/insert`,
    updateCoursePhaseTerm: `${serverLocation}/courseTermPhase/updateTermAssign`,

    //link Course Subject to Course Term
    assignCourseSubjectToCourseTerm: `${serverLocation}/courseTermSubject/insert`,
    updateCourseSubjectToCourseTerm: `${serverLocation}/courseTermSubject/update`,

    //add Topic to subject
    addTopicsToSubject: `${serverLocation}/courseTopic/insert`,
    updateCourseTopic: `${serverLocation}/courseTopic/update`,
    deleteCourseTopic: `${serverLocation}/courseTopic/delete`,
    getCourseTopic: `${serverLocation}/courseTopic/`,

    //Course Class
    getCourseClass: `${serverLocation}/courseClass/`,
    insertCourseClass: `${serverLocation}/courseClass/insert`,

    //Course Class Resources
    insertCourseClassResources: `${serverLocation}/courseClassResource/insert`,

    //Course Class Assignment
    insertCourseClassAssignment: `${serverLocation}/courseClassAssignment/insert`,
    getCourseClassAssignment: `${serverLocation}/courseClassAssignment/`,
    submitAssignment: `${serverLocation}/studentAssignment/insert`,
    getCourseAssignmentSubmissionList: `${serverLocation}/studentAssignment/`,

    //Course Syllabus
    getCoursePlan: `${serverLocation}/coursePlan/`,
    getCoursePlanCore: `${serverLocation}/coursePlan/coreSelect`,
    insertCoursePlan: `${serverLocation}/coursePlan/coreInsert`,
    deleteCoursePlan: `${serverLocation}/coursePlan/deleteCore`,
    deleteCoursePlanAcademic: `${serverLocation}/coursePlan/delete`,
    updateCoursePlanSyllabusDetail: `${serverLocation}/coursePlanSyllabus/update`,
    updateCoursePlanSyllabusDetailCore: `${serverLocation}/coursePlanSyllabus/updateCore`,
    deleteCoursePlanSyllabusDetail: `${serverLocation}/coursePlanSyllabus/delete`,
    deleteCoursePlanSyllabusDetailCore: `${serverLocation}/coursePlanSyllabus/deleteCore`,

    insertCoursePlanSyllabus: `${serverLocation}/coursePlanSyllabus/insertCore`,

    // courseplan insert
    coursePlanInsert: `${serverLocation}/coursePlan/insert`,

    //Course Plan Resources
    insertCoursePlanResources: `${serverLocation}/coursePlanResources/insert`,
    getCoursePlanResources: `${serverLocation}/coursePlanResources/selectBykey`,
    insertCoursePlanResourcesCore: `${serverLocation}/coursePlanResources/insertCore`,
    getCoursePlanResourcesCore: `${serverLocation}/coursePlanResources/selectBykeyCore`,
    deleteCoursePlanResources: `${serverLocation}/coursePlanResources/deleteCore`,
    // PLanner
    deletePlanner: `${serverLocation}/planner/delete`,
    getSubjectForPlanner: `${serverLocation}/subjectClassroomTeacher/getUniqueSubjectByClassroom`,
    insertPlanner: `${serverLocation}/planner/insert`,
    searchPlanner: `${serverLocation}/planner/search`,
    updatePlanner: `${serverLocation}/planner/update`,

    // Worksheet
    insertWorksheet: `${serverLocation}/workSheet/insert`,
    getWorksheet: `${serverLocation}/workSheet/`,

    //Notification
    insertNotification: `${serverLocation}/notification/insert`,
    updateNotification: `${serverLocation}/notification/update`,
    deleteNotification: `${serverLocation}/notification/delete`,
    searchNotification: `${serverLocation}/notification/search`,
    sendNotification: `${serverLocation}/notification/notifyNow`,

    getAllNotification: `${serverLocation}/notification/`,
    insertNotificationType: `${serverLocation}/notificationType/insert`,
    getAllNotificationsType: `${serverLocation}/notificationType/`,

    //substitute teacher and role
    insertSubstituteTeacherRole: `${serverLocation}/substituteClassroomTeacher/insertRole`,
    deleteSubstituteTeacherRole: `${serverLocation}/substituteClassroomTeacher/deleteTeacherRole`,
    getSubstituteTeachers: `${serverLocation}/substituteClassroomTeacher/selectTeachers`,
    getSubstituteTeacherRole: `${serverLocation}/substituteClassroomTeacher/selectRoles`,
    insertSubstituteTeacherRolePermission: `${serverLocation}/substituteClassroomTeacher/insertRolePermissions`,
    getSubstituteTeacherRolePermission: `${serverLocation}/substituteClassroomTeacher/selectRolePermissions`,
    assignSubstituteRoleToTeacher: `${serverLocation}/substituteClassroomTeacher/insertRoleTeacher`,
    getClassroomTeacherPermissions: `${serverLocation}/classroom/selectClassroomTeacherPermissions`,

    //get moderations
    getModerations: `${serverLocation}/moderationHandler/`,
    insertModeration: `${serverLocation}/moderationHandler/insert`,

    //people document select
    getPeopleDocument: `${serverLocation}/peopleDocument/selectByKey`,

    //accounting
    insertFeeCategory: `${serverLocation}/feeCategory/insert`,
    getAllFeeCategory: `${serverLocation}/feeCategory/`,
    deleteFeeCategory: `${serverLocation}/feeCategory/delete`,
    updateFeeCategory: `${serverLocation}/feeCategory/update`,

    //fee configuration
    insertFeeCategoryConfiguration: `${serverLocation}/feeCategoryConfiguration/insert`,
    selectFeeCategoryConfiguration: `${serverLocation}/feeCategoryConfiguration/`,
    selectGeneralFeeCategoryConfiguration: `${serverLocation}/feeCategoryConfiguration/`,

    //account student
    getListOfCurrentStudent: `${serverLocation}/admission/getListOfCurrentStudent`,

    //month configuration
    getMonthConfiguration: `${serverLocation}/monthConfiguration/`,
    updateMonthConfiguration: `${serverLocation}/monthConfiguration/update`,

    //get months
    getMonthByConfiguration: `${serverLocation}/month/`,
    //bill generation
    insertStudentBill: `${serverLocation}/studentBill/insert`,
    getMonthByGeneratedBill: `${serverLocation}/studentBill/getMonthByStudent`,
    getStudentBill: `${serverLocation}/studentBill/getConfigForBillPayment`,
    insertPayment: `${serverLocation}/studentBill/paybill`,
    getInventories: `${serverLocation}/inventoryItem/`,
    insertInventory: `${serverLocation}/inventoryItem/insert`,

    insertInventoryQuantity: `${serverLocation}/inventoryItemStockLevel/insert`,
    insertStudentInventory: `${serverLocation}/studentInventoryItem/insert`,
    updateStudentInventory: `${serverLocation}/studentInventoryItem/update`,

    getStudentInventory: `${serverLocation}/studentInventoryItem/`,
    getStudentAsPerYearLevel: `${serverLocation}/admission/getStudentAsPerYearLevel`,

    // draft bils

    getDraftBills: `${serverLocation}/studentBill/getDraftBills`,
    generateBillFromDraft: `${serverLocation}/studentBill/generateBillFromDraft`,

    // payment transaction

    getPaymentHistory: `${serverLocation}/studentBill/listPaymentTransaction`,

    //attendance
    getSubjectByClassroomForAttendance: `${serverLocation}/subjectClassroomTeacher/getSubjectByClassroomForAttendance`,
    getDailyAttendanceSummary: `${serverLocation}/studentAttendance/dailySummary`,
    getClassForAttendance: `${serverLocation}/subjectClassroomTeacher/getUniqueSubjectByClassroom`,

    //exam
    selectSubjectQuestion: `${serverLocation}/subjectQuestion/`,
    selectQuestionAnswerExamByPeople: `${serverLocation}/subjectQuestion/selectQuestionAnswerByPeople`,
    insertStudentAnswer: `${serverLocation}/subjectQuestion/insertStudentAnswer`,
    selectQuestionAnswerByPeople: `${serverLocation}/subjectQuestion/selectQuestionAnswerByPeople`,
    checkAnswerForSystemExam: `${serverLocation}/subjectQuestion/checkAnswer`,

    //general settings
    storeGeneralSetting: `${serverLocation}/generalSetting/`,
    getAllGeneralSetting: `${serverLocation}/generalSetting/selectAll`,
    getByKeyGeneralSetting: `${serverLocation}/generalSetting/selectByKey`,
    updateGeneralSetting: `${serverLocation}/generalSetting/update`,

    //school LMS

    insertSchoolLMS: `${serverLocation}/schoolLms/insert`,

    getSchoolLMS: `${serverLocation}/schoolLms/select`,

    updateSchoolLMS: `${serverLocation}/schoolLms/update`,
    deleteSchoolLMS: `${serverLocation}/schoolLms/delete`,
    udpateStatus: `${serverLocation}/school/enableDisableSchool`,

    addExtraLMSResources: `${serverLocation}/schoolLms/addResources`,

    deleteLMSResources: `${serverLocation}/schoolLms/deleteResources`,

    deleteLMSData: `${serverLocation}/schoolLms/delete`,
    //IdCardConfig

    insertIdCardConfig: `${serverLocation}/idCard/insertIdCardConfig`,
    getIdCardConfig: `${serverLocation}/idCard/getIDCardConfig`,
    //getAllEmail
    emailConfigurationGetAll: `${serverLocation}/emailConfiguration/select`,

    //leave types

    getLeaveTypes: `${serverLocation}/leaveType/select`,

    // leave management
    sendLeaveReq: `${serverLocation}/leave/insert`,
    getAllLeaveList: `${serverLocation}/leave/select`,
    changeLeaveStatus: `${serverLocation}/leave/updateLeaveStatus`,
    getStaffLeaveList: `${serverLocation}/leave/getAppliedStaffList`,

    getStdContactChildren: `${serverLocation}/studentContact/selectByKey`,
    getStudentByContactPeople: `${serverLocation}/studentContact/getStudentByContactPeople`,

    getStudentLeaveList: `${serverLocation}/leave/getAppliedStudentList`,
    getAllPermission: `${serverLocation}/user/getAssignedUserActivities`,
    assignUserBasedPermission: `${serverLocation}/user/reassignUserPermission`,
    createSubRole: `${serverLocation}/permission/createNewSubroleAndAssignPermission`,

    // menu
    insertMenu: `${serverLocation}/menu/insert`,
    getAllMenu: `${serverLocation}/menu/selectAll`,
    deleteMenu: `${serverLocation}/menu/delete`,

    changeMenuStatus: `${serverLocation}/menu/update`,

    // slider
    insertslider: `${serverLocation}/slider/insert`,
    getAllslider: `${serverLocation}/slider/selectAll`,
    deleteslider: `${serverLocation}/slider/delete`,
    changeSliderStatus: `${serverLocation}/slider/update`,
    updateSlider: `${serverLocation}/slider/update`,

    // training
    insertTraining: `${serverLocation}/current-training/insert`,
    getAllTraining: `${serverLocation}/current-training/selectCurrentTrainings`,
    updateAllTraining: `${serverLocation}/current-training/update`,
    deleteTraining: `${serverLocation}/current-training/delete`,
    deleteTrainingImg: `${serverLocation}/current-training/deleteTrainingImage`,

    changeTrainingStatus: `${serverLocation}/current-training/changeTrainingStatus`,

    // main feature
    insertFeature: `${serverLocation}/coreFeatures/insert`,
    getAllFeature: `${serverLocation}/coreFeatures/select`,
    updateFeature: `${serverLocation}/coreFeatures/update`,
    deleteFeature: `${serverLocation}/coreFeatures/delete`,

    getEducationlevel: `${serverLocation}/educationlevel/selectAll`,
    getAllClasses: `${serverLocation}/classes/selectAll`,
    getSubjectByClass: `${serverLocation}/coursePlan/getSubjectByClass`,
    getAllCoursePlan: `${serverLocation}/coursePlan/coreSelect`,
    getLoggedInUserInfo: `${serverLocation}/user/getLoggeduser`,

    //exam core
    insertExam: `${serverLocation}/exam/insert`,
    updateExam: `${serverLocation}/exam/update`,

    getExamQuestions: `${serverLocation}/exam/selectByCurrentTraining`,
    insertExamAnswer: `${serverLocation}/exam/insertExamAnswer`,
    getExamAnswer: `${serverLocation}/exam/getSelectedAnswer`,
    checkExamAnswer: `${serverLocation}/exam/checkExam`,
    getAllExamList: `${serverLocation}/exam/selectAll`,
    deleteExamList: `${serverLocation}/exam/delete`,
    getAllExamQuestionAnswer: `${serverLocation}/current-training/getAllData`,

    // teacher syllabus
    insertTeacherTrainingSyllabus: `${serverLocation}/teacherSyllabus/insert`,
    getAllTeacherTrainingSyllabus: `${serverLocation}/teacherSyllabus/select`,

    //statistics
    getSchoolByGeography: `${serverLocation}/school/getSchoolByGeography`,
    getLoginStatisticsDetail: `${serverLocation}/user/getLoginStatisticsDetail`,

    //province
    getProvinces: `${serverLocation}/countryFirstLevel/`,
    getDistricts: `${serverLocation}/district/select`,
    getCities: `${serverLocation}/city/select`,
    searchCalendarEvents: `${serverLocation}/calendarEvent/search`,
    getAllAssignedUsersByUserRole: `${serverLocation}/userRole/getUserDataByUserRole`,
    getTodayLoginStatistics: `${serverLocation}/user/getTodayLoginStatistics`,

    //education level master
    insertEducationLevel: `${serverLocation}/educationLevel/insertCore`,
    getAllEducationLevel: `${serverLocation}/educationLevel/selectAllCore`,
    getCoreEducationLevel: `${serverLocation}/educationLevel/selectCore`,
    deleteEducationlevel: `${serverLocation}/educationLevel/deleteCore`,
    updateEducationLevel: `${serverLocation}/educationLevel/updateCore`,

    //getclassforeducationlevel

    getClassForLevel: `${serverLocation}/yearLevel/getYearLevelByEducationLevelCore`,
    //random collage images

    getRandomCollageImg: `${serverLocation}/current-training/selectRandomTraining`,

    //class
    insertClass: `${serverLocation}/classroom/insertCore`,
    getAllClass: `${serverLocation}/classroom/selectAllCore`,
    deleteClass: `${serverLocation}/classroom/deleteCore`,
    updateClass: `${serverLocation}/classroom/updateCore`,

    //subject
    insertSubject: `${serverLocation}/subject/insertCore`,
    getAllSubject: `${serverLocation}/subject/getSubjectsCore`,
    deleteSubject: `${serverLocation}/subject/deleteCore`,
    updateSubjectCore: `${serverLocation}/subject/updateCore`,

    //school
    getAllSchools: `${serverLocation}/school/`,
    getAllSchoolSummary: `${serverLocation}/school/selectSchoolUserRoleCount`,
    getSchoolByCode: `${serverLocation}/school/getSchoolByCode`,

    getSchoolDataByMonth: `${serverLocation}/school/getSchoolByJoiningMonth`,
    insertSchool: `${serverLocation}/school/insert`,
    insertSchoolCore: `${serverLocation}/school/insertCore`,
    deleteSchool: `${serverLocation}/school/delete`,
    updateSchool: `${serverLocation}/school/update`,
    updateSchoolCore: `${serverLocation}/school/updateCore`,
    generateSchoolImportFormat: `${serverLocation}/generateSchoolFormat`,

    //calendar event
    insertCalendarEventCore: `${serverLocation}/calendarEvent/insertCore`,
    updateCalendarEventCore: `${serverLocation}/calendarEvent/updateCore`,
    deleteCalendarEventCore: `${serverLocation}/calendarEvent/deleteCore`,
    selectCalendarEventCore: `${serverLocation}/calendarEvent/selectCore`,
    searchCalendarEventCore: `${serverLocation}/calendarEvent/search`,

    //addActivity: `${serverLocation}/module/addActivities`,

    insertRole: `${serverLocation}/permission/insertCore`,
    getAllRoles: `${serverLocation}/permission/getUserRoles`,
    // getUserRolesCore: `${serverLocation}/permission/getUserRolesCore`,

    getPermissionByUserRoles: `${serverLocation}/permission/getPermssionByUserRoleCore`,
    updatePermission: `${serverLocation}/permission/update`,
    updatePermissionCore: `${serverLocation}/permission/updateCore`,

    //register
    signUp: `${serverLocation}/signUp`,
    userRegister: `${serverLocation}/register`,
    editUser: `${serverLocation}/user/userDataUpdate`,
    getAllUserType: `${serverLocation}/userRole/getAll`,
    getAdminFilteredRoles: `${serverLocation}/userRole/selectAdminFilteredRoles`,

    //feedbacks
    getFeedbackTypes: `${serverLocation}/feedback/getAllFeedbackTypes`,
    insertFeedback: `${serverLocation}/feedback/insert`,
    getAllFeedbacks: `${serverLocation}/feedback/getAllFeedbacks`,

    // master users
    getMasterUsers: `${serverLocation}/user/getMasterUser`,

    // website configurations

    insertWebsiteConfig: `${serverLocation}/websiteConfiguration/insert`,
    getWebsiteConfig: `${serverLocation}/websiteConfiguration/`,
    updateWebsiteConfig: `${serverLocation}/websiteConfiguration/update`,
    //other activities
    getOtherActivities: `${serverLocation}/otherActivitiesCategories/`,
    insertOtherActivityCategories: `${serverLocation}/otherActivitiesCategories/insert`,

    //other activities data

    insertOtherActivityData: `${serverLocation}/otherActivitiesCategoriesResources/insert`,
    getAllActivityData: `${serverLocation}/otherActivitiesCategoriesResources/`,
    deleteActivityResource: `${serverLocation}/otherActivitiesCategoriesResources/delete`,

    //Search
    searchResources: `${serverLocation}/search/readingMaterials`,
    searchUserProfile: `${serverLocation}/search/searchByName`,
    searchGroupProfile: `${serverLocation}/search/searchGroup`,
    //insert notice core
    insertNoticeCore: `${serverLocation}/post/insertForCentral`,
    getNoticeCore: `${serverLocation}/post/getPostforPowerUser`,
    searchNoticeCore: `${serverLocation}/search/searchNotice`,

    //activity
    insertActivity: `${serverLocation}/activity/insertCore`,

    //relative links

    insertRelativeLink: `${serverLocation}/relative-links/insert`,
    getAllRelativeLinks: `${serverLocation}/relative-links/select`,
    updateRelativeLinks: `${serverLocation}/relative-links/update`,
    deleteRelativeLinks: `${serverLocation}/relative-links/delete`,

    //School Management History
    getSchoolHistory: `${serverLocation}/schoolHistory/select`,
    updateViewerStat: `${serverLocation}/resourceViewerStat/update`,
    getViewerStat: `${serverLocation}/resourceViewerStat/`,
    getResourceViewerStat: `${serverLocation}/resourceViewerStat/count`,
    getCentralAcademicRepo: `${serverLocation}/academicRepository/getAcademicRepoSchoolWise`,
    
    // exam module data
    getRoutineData: `${serverLocation}/assessment/getSubjectListForSubjectAssessmentSetting`,
  },
  prrCode = {
    code: "PRR",
  },
  srrCode = {
    code: "SRR",
  },
  hrrCode = {
    code: "HSR",
  },
  addressCode = "pre-addr",
  genderCode = "pre-gndr",
  academicYearCode = "pre-acay",
  yearLevelCode = "pre-ylvl",
  peopleCode = "pre-peop",
  postType = {
    noticeCode: "PT_NOTE",
    articleCode: "PT_ARTI",
    mediaCode: "PT_MEDI",
    arrNoticeCode: "PT_ARRN",
    videoConference: "PT_VCON",
    mcq: "PT_MCQ",
    profilePictureNotice: "PT_PPNT",
    coverPictureNotice: "PT_CPNT",
    postModeration: "PT_POST",
    assignment: "PT_ASSI",
    //post Comment MOderation
    postCommentModeration: "PT_PCMT",
    //AcademicRepo comment Moderation
    AcademicCommentModeration: "PT_ARCM",
    //AcademicPost Comment Moderation
    academicPostCommentModeration: "PT_ARCM",
    discussion: "PT_DISC",
  };

export const scaleTypeCode = {
  level: "sct-lvl",
  gradePoint: "sct-grd",
  mark: "sct-mrk",
};

export const admissionStatus = {
  admissionApplied: "ad-app",
  admissionApproved: "ad-appr",
  admissionRejected: "ad-rej",
  admissionCurrent: "ad-cur",
  admissionLeftSchool: "ad-lef",
  admissionSuspended: "ad-sus",
  admissionTerminated: "ad-ter",
  admissionRetired: "ad-ret",
};
export const recruitmentStatus = {
  recruitmentApplied: "rt-app",
  recruitmentApproved: "rt-appr",
  recruitmentRejected: "rt-rej",
  recruitmentCurrent: "rt-cur",
  recruitmentLeftSchool: "rt-lef",
  recruitmentSuspended: "rt-sus",
  recruitmentTerminated: "rt-ter",
  recruitmentRetired: "rt-ret",
};

export const dbTable = {
  generalAssessmentOverallConfiguration:
    "assessment_general_custom_assessment_configuration",
  generalAssessmentRubricsConfiguration:
    "assessment_general_custom_assessment_rubrics_configuration",
  subjectAssessmentConfigurationGroup:
    "assessment_subject_scale_configuration_group",
  assessmentScale: "assessment_scale",
  presetAssessmentScaleGradePoint: "preset_assessment_scale_grade_point",
  assessmentGeneralCommentConfiguration:
    "assessment_general_comment_configuration",
  reportSetup: "report_setup",
  school: "school",
  educationLevel: "education_level",
  yearLevel: "year_level",
  classroom: "classroom",
  studentClassroom: "student_classroom",
  academicYear: "academic_year",
  admission: "admission", //if admission get people data to display name
  separator: "separator",
  pageNumber: "page_number",
  resultTable: "result_table",
};

export const headers = {
  Authorization: `Bearer ${getAccessToken()}`,
};

export function getAccessToken() {
  return localStorage.getItem("token");
}

export const regEx = /^[0-9a-zA-Z\-!;,:"' ]*$/;

export const userRoleCode = {
  roleStudent: "RL_STD",
  roleAdmin: "RL_ADM",
  roleStudentContact: "RL_STC",
  roleStaff: "RL_STF",
  roleOther: "RL_OTH",
  roleCentralAdmin: "RL_CENTRAL",
  roleNationalAdmin: "RL_NATION",
  roleSuperAdmin: "RL_SUPER",
  roleDistrictAdmin: "RL_DISTRICT",
};

export const marksBreakdownTypeCode = {
  downgradeToPercentAndSummation: "d2ps",
  average: "avrg",
  summation: "sums",
};

// export const imageSelectURL = "http://localhost:8090/";
// export const imageSelectURL =
//   "http://localhost:8080/xvzfyhojcdeQnrIvAZVmgf3Sb1AoNmfA.ZQz851AchimYgyRW8.rgSFIdxjur80Wefgjkhskjhdnkdslkdji/";

export const userRole = CryptoJS.AES.decrypt(
  localStorage.getItem("role") || "",
  "saurav_imagine_sys"
).toString(CryptoJS.enc.Utf8);

export const isCoordinator = localStorage.getItem("isCoordinator");
export const isHomeRoomTeacher = localStorage.getItem("isHomeRoomTeacher");
export const profilePeopleId = localStorage.getItem("peopleId");
export const mainUserId = localStorage.getItem("mainUserId");
export const familyId = localStorage.getItem("familyId");
export const schoolProfileGroup = localStorage.getItem(
  "educationCommunityGroupName"
);

export const imageDisplayUrl = "http://192.168.1.116:8090/fileUpload/download/";
// export const fileDownloadURL = "http://app.techarttrekkies.com/fileDownload/";
export const reportDownloadURL = "http://103.147.226.35:8090/reportDownload/";
export const getClassroomName = localStorage.getItem("classroom");
export const getYearLevel = localStorage.getItem("yearLevel");

export const schoolSetup = localStorage.getItem("schoolSetup");
export const academicYears = localStorage.getItem("academicYears");
export const isFirstLogin = localStorage.getItem("isFirstLogin");

export function checkRolePermissions(method, param, arrName) {
  // return true;
  let widgets = getPermissionFromStorage();
  let widgetIndex,
    moduleIndex,
    activityIndex = -1;
  let moduleTrue = false;
  let activityTrue = false;
  if (param === "widgets") {
    widgetIndex = widgets.findIndex((widget) => widget.name === method);
    if (widgetIndex !== -1) return true;
    else return false;
  } else if (param === "modules") {
    if (arrName) {
      let widgetIndex = widgets.findIndex((widget) => widget.name === arrName);
      if (widgetIndex !== -1) {
        // widgets.forEach((widget) => {
        moduleIndex = widgets[widgetIndex].modules.findIndex(
          (module) => module.name === method
        );
        if (moduleIndex !== -1) {
          moduleTrue = true;
        }
        //});
        if (moduleTrue) return true;
        else return false;
      }
    }
    // else {
    //     widgets.forEach((widget) => {
    //       moduleIndex = widget.modules.findIndex(
    //         (module) => module.name === method
    //       );
    //       if (moduleIndex !== -1) {
    //         moduleTrue = true;
    //       }
    //       if (moduleTrue) return true;
    //       else return false;
    //     });
    // }
  } else {
    if (arrName) {
      let widgetIndex = widgets.findIndex((widget) => widget.name === arrName);
      if (widgetIndex !== -1) {
        widgets[widgetIndex].modules.forEach((module) => {
          activityIndex = module.activities.findIndex(
            (activity) => activity.activityCode === method
          );
          if (activityIndex !== -1) {
            activityTrue = true;
          }
        });
      }
    } else {
      widgets.forEach((widget) => {
        widget.modules.forEach((module) => {
          activityIndex = module.activities.findIndex(
            (activity) => activity.activityCode === method
          );
          if (activityIndex !== -1) {
            activityTrue = true;
          }
        });
      });
    }
    if (activityTrue) return true;
    else return false;
  }
}

export function getPermissionFromStorage() {
  let widgets = localStorage.getItem("widgets") || "[]";
  widgets = CryptoJS.AES.decrypt(widgets.toString(), "saurav_imagine_sys");
  try {
    widgets = JSON.parse(widgets.toString(CryptoJS.enc.Utf8)) || [];
  } catch (err) {
    localStorage.clear();
    history.push("/");
  }
  return widgets;
}

export function checkUserRole() {
  const verifiedRoles = [
    "ROLE_SUPERADMIN",
    "ROLE_CENTRAL",
    "RL_SUPER",
    "RL_CENTRAL",
    "RL_NATION",,
    "RL_PROVINCE",
    "RL_LOCAL",
    "RL_DISTRICT",
  ];
  let encryptedData = localStorage.getItem("role") || "[]";
  let authUser = false;
  if (encryptedData) {
    var bytes = CryptoJS.AES.decrypt(encryptedData, "saurav_imagine_sys");
    var userRole = bytes.toString(CryptoJS.enc.Utf8);
    verifiedRoles.forEach((el) => {
      if (userRole === el) {
        authUser = true;
      }
    });
  }
  return authUser;
}

export function checkCentralRole() {
  let encryptedData = localStorage.getItem("role") || "[]";
  if (encryptedData) {
    var bytes = CryptoJS.AES.decrypt(encryptedData, "saurav_imagine_sys");
    var userRole = bytes.toString(CryptoJS.enc.Utf8);
    if (userRole === "ROLE_CENTRAL" || userRole === "RL_CENTRAL") {
      return true;
    } else {
      return false;
    }
  }
  return false;
}

export function getLinkedAccount() {
  let accounts = localStorage.getItem("associatedUsers") || "[]";
  accounts = CryptoJS.AES.decrypt(accounts.toString(), "saurav_imagine_sys");
  try {
    accounts = JSON.parse(accounts.toString(CryptoJS.enc.Utf8)) || [];
  } catch (err) {
    // localStorage.clear();
    // history.push("/");
  }
  return accounts;
}
export function getSubsTeacherPermissionFromStorage() {
  let substituteTeacherRolePermissions =
    localStorage.getItem("substituteTeacherRolePermissions") || "[]";
  substituteTeacherRolePermissions = CryptoJS.AES.decrypt(
    substituteTeacherRolePermissions.toString(),
    "saurav_imagine_sys"
  );
  try {
    substituteTeacherRolePermissions =
      JSON.parse(
        substituteTeacherRolePermissions.toString(CryptoJS.enc.Utf8)
      ) || [];
  } catch (err) {
    localStorage.clear();
    history.push("/");
  }
  return substituteTeacherRolePermissions;
}

export function checkSubsTeacherPermissions(param, name, widgetName) {
  const permissions = getSubsTeacherPermissionFromStorage();
  if (name && name === "widgets") {
    const widgetIndex = permissions.findIndex(
      (permission) => permission.widgetName === param
    );
    if (widgetIndex !== -1) return true;
    else return false;
  } else if (name && name === "modules") {
    const moduleIndex = permissions.findIndex(
      (permission) =>
        permission.moduleName === param && permission.widgetName === widgetName
    );
    if (moduleIndex !== -1) return true;
    else return false;
  } else {
    const activityIndex = permissions.findIndex(
      (permission) =>
        permission.widgetName === widgetName &&
        permission.activityCode === param
    );
    if (activityIndex !== -1) return true;
    else return false;
  }
}

export function draggable() {
  if ($(".swal-modal").draggable) {
    $(".swal-modal").draggable({
      containment: ".swal-overlay",
      handle: ".swal-title",
    });
  }
}

/**
 *
 * @param {*} widgetCode
 * @author Saurav Sitaula
 */
export function checkWidgetAdminOrNot(widgetCode) {
  if (userRole === "RL_ADM") {
    return true;
  } else {
    let subRoles = localStorage.getItem("subRoles") || "[]";
    subRoles = CryptoJS.AES.decrypt(subRoles.toString(), "saurav_imagine_sys");
    try {
      subRoles = JSON.parse(subRoles.toString(CryptoJS.enc.Utf8)) || [];
    } catch (err) {
      localStorage.clear();
      history.push("/");
    }
    if (widgetCode === "Academic Administration") {
      let index = subRoles.findIndex(
        (subRole) => subRole.subRoleCode === "ACA_ADMIN"
      );
      if (index !== -1) return true;
    } else if (widgetCode === "Academic Repository") {
      let index = subRoles.findIndex(
        (subRole) => subRole.subRoleCode === "ACA_REPO_ADMIN"
      );
      if (index !== -1) return true;
    } else if (widgetCode === "Admission") {
      let index = subRoles.findIndex(
        (subRole) => subRole.subRoleCode === "ADM_ADMIN"
      );
      if (index !== -1) return true;
    } else if (widgetCode === "Recruitment") {
      let index = subRoles.findIndex(
        (subRole) => subRole.subRoleCode === "REC_ADMIN"
      );
      if (index !== -1) return true;
    } else if (widgetCode === "Attendance") {
      let index = subRoles.findIndex(
        (subRole) => subRole.subRoleCode === "ATD_ADMIN"
      );
      if (index !== -1) return true;
    } else if (widgetCode === "Video Conference") {
      let index = subRoles.findIndex(
        (subRole) => subRole.subRoleCode === "VC_ADMIN"
      );
      if (index !== -1) return true;
    } else if (widgetCode === "Teaching Tool") {
      let index = subRoles.findIndex(
        (subRole) => subRole.subRoleCode === "TT_ADMIN"
      );
      if (index !== -1) return true;
    } else if (widgetCode === "User Setting") {
      let index = subRoles.findIndex(
        (subRole) => subRole.subRoleCode === "US_ADMIN"
      );
      if (index !== -1) return true;
    } else if (widgetCode === "LMS") {
      let index = subRoles.findIndex(
        (subRole) => subRole.subRoleCode === "LMS_ADMIN"
      );
      if (index !== -1) return true;
    } else {
      //added for assessment module check 12-25
      axiosPost(URL.getAssesmentModules, {}, (response) => {
        if (response.status === 200) {
          const assessmentModules = response.data.data;
          if (assessmentModules !== null) {
            for (let i = 0; i < assessmentModules.length; i++) {
              if (widgetCode === assessmentModules[i].name) {
                let index = subRoles.findIndex(
                  (subRole) =>
                    subRole.subRoleCode === `${assessmentModules[i].code}_ADMIN`
                );
                if (index !== -1) return true;
                else return false;
              }
            }
          }
        }
      });
    }
  }
  return false;
}

export function checkDevAdmin() {
  let subRoles = localStorage.getItem("subRoles") || "[]";
  subRoles = CryptoJS.AES.decrypt(subRoles.toString(), "saurav_imagine_sys");
  try {
    subRoles = JSON.parse(subRoles.toString(CryptoJS.enc.Utf8)) || [];
  } catch (err) {
    localStorage.clear();
    history.push("/");
  }
  if (
    subRoles.findIndex((subRole) => subRole.subRoleCode === "DEV_ADMIN") !== -1
  )
    return true;
  else return false;
}

export function checkSystemAdmin() {
  let subRoles = localStorage.getItem("subRoles") || "[]";
  subRoles = CryptoJS.AES.decrypt(subRoles.toString(), "saurav_imagine_sys");
  try {
    subRoles = JSON.parse(subRoles.toString(CryptoJS.enc.Utf8)) || [];
  } catch (err) {
    localStorage.clear();
    history.push("/");
  }
  if (
    subRoles.findIndex((subRole) => subRole.subRoleCode === "SYS_ADMIN") !== -1
  )
    return true;
  else return false;
}

export function checkForSubRoles(subRoleCode) {
  let subRoles = localStorage.getItem("subRoles") || "[]";
  subRoles = CryptoJS.AES.decrypt(subRoles.toString(), "saurav_imagine_sys");
  try {
    subRoles = JSON.parse(subRoles.toString(CryptoJS.enc.Utf8)) || [];
  } catch (err) {
    localStorage.clear();
    history.push("/");
  }
  if (
    subRoles.findIndex((subRole) => subRole.subRoleCode === subRoleCode) !== -1
  )
    return true;
  else return false;
}

export function getSubRolesFromStorage() {
  let subRoles = localStorage.getItem("subRoles") || "[]";
  subRoles = CryptoJS.AES.decrypt(subRoles.toString(), "saurav_imagine_sys");
  try {
    subRoles = JSON.parse(subRoles.toString(CryptoJS.enc.Utf8)) || [];
  } catch (err) {
    localStorage.clear();
    history.push("/");
  }
  return subRoles;
}

export const username = localStorage.getItem("username");
export const password = localStorage.getItem("password");
export const rememberMe = localStorage.getItem("rememberMe");

export function getValidHrefLink(link) {
  if (link) {
    return link.includes("http") ? link : "http://" + link;
  } else return null;
}

export function checkPermissionForSubstituteTeachers(
  peopleId,
  assessmentModuleCode,
  assessmentModules
) {
  let bool = false;
  if (userRole === userRoleCode.roleAdmin) bool = true;
  else if (checkWidgetAdminOrNot("Academic Administration") === true) {
    bool = true;
  } else if (
    checkForSubRoles("HOME_STF") &&
    parseInt(profilePeopleId) === peopleId
  ) {
    bool = true;
  } else {
    assessmentModules.forEach((assessmentModule) => {
      if (
        assessmentModuleCode === assessmentModule.code &&
        checkForSubRoles(`${assessmentModule.code}_ADMTR`)
      )
        bool = true;
    });
  }
  return bool;
}

export const checkForSpecialCharacters = (str) => {
  if (/^[0-9a-zA-Z\-;,:"'() ]*$/.test(str)) {
    return true;
  } else {
    // swal("Error", "Cannot enter special charaters!!");
  }
};

export const rowCountLimit = 6;

export const modules = {
  Calendar: "calendar",
  School: "school",
  "Subject Setup": "sub-setup",
  "Recording Setup": "rec-setup",
  "Reporting Setup": "rep-setup",
  Reports: "reports",
  "Academic Years": "aca-years",
  "School Groupings": "sch-groupings",
  "Assessment Module": "ass-module",
  "Education Community": "edu-community",
  "Admission Configuration": "adm-conf",
  Admission: "admission",
  "Recruitment Configuration": "rec-conf",
  Recruitment: "recruitment",
  "Student Attendance": "stu-attendance",
  "Notice Section": "not-section",
  "Post Section": "post-section",
  "Post Comment Section": "post-cmnt-section",
  "Academic Repository": "aca-repo",
  Recording: "recording",
  Conference: "conference",
  MCQ: "mcq",
  "Profile Picture": "prof-pic",
  "Cover Picture": "cov-pic",
  "User List": "user-list",
  "Flash Cards": "flash-card",
  Programs: "programs",
};

export const questionType = {
  SUBJECTIVE: "SUBJECTIVE",
  OBJECTIVE: "OBJECTIVE",
  NONANSWERABLE: "NONANSWERABLE",
};

export const moduleName = {
  dashboard: "Dashboard",
  myProfile: "My Profile",
  eventType: "Event Type",
  events: "Events",
  calendar: "Calendar",
  schoolManagement: "School Management",
  userManagement: "User Management",
  MODULE: "Modules",
  ROLES: "Roles",
  CMS: "CMS",
  EDUCATION_LEVEL: "Education Level",
  ClASS: "Class",
  FEATURES: "Features",
  SLIDER: "Slider",
  TRAINING: "Training",
  SYLLABUS: "Syllabus",
  READING_MATERIALS: "Reading Materials",
  CURRICULUM: "Curriculum",
  TEXTBOOKS: "Textbooks",
  QUESTIONBANK: "Question Bank",
  AUDIO_VISUAL: "Audio Visual",
  SIGN_LANGUAGE: "Sign Language",
  GAME_BASED_LEARNING: "Game Based Learning",
  MCQ: "MCQ",
  FLASHCARD: "Flashcard",
  VIDEO_CONFERENCE: "Video Conference",
  EXAM: "Exam",
  EXAM_CONFIG: "Exam Config",
  EXAM_LIST: "Exam List",
  CHECK_EXAM: "Check Exam",
  WEBSITE_CONFIGURATION: "Website Configuration",
  SLIDER: "Slider",
  MENU: "Menu",
  MAIN_FEATURE: "Main Feature",
  SITE_CONFIGURATION: "Site Configuration",
  SCHOOL_MANAGEMENT: "School Management",
  USER_MANAGEMENT: "User Management",
  GROUPS: "Group",
  DISCUSSION: "Discussion",
  ROLE_MANAGEMENT: "Role Management",
  OTHER_ACTIVITIES: "Other Activities",
  CATEGORY: "Category",
  RELATIVE_LINKS: "Relative Links",
  REPORTS: "Reports",
  SCHOOL_STATISTICS: "School Statistics",
  USER_LOGIN_STATISTICS: "User Login Statistics",
  RESOURCES_SUMMARY: "Resources Summary",
  MY_PROFILE: "My Profile",
  FEEDBACK: "Feedback",
  NOTICES: "Notice",
};

export const fileTypes = {
  pdf: "PDF",
  image: "IMG",
  link: "LNK",
  audio: "AUD",
  video: "VID",
};
