import React, { Component } from "react";

import NoteDisplay from "../../../utils/noteDisplay";
import SubjectSettings from "./SubjectSettings";

class SettingSubjects extends Component {
  state = {};

  render() {
    return this.props.isSettingAvailable ? (
      <div className="tt-widgetContent-tab-holder" style={{ height: "78.7vh" }}>
        <SubjectSettings
          subjectId={this.props.id}
          recruitments={this.props.recruitments}
          code={this.props.code}
          subjects={this.props.subjects}
        />
      </div>
    ) : (
      <div className="tt-widgetContent-tab-holder" style={{ height: "78.7vh" }}>
        <NoteDisplay textContent="To change subject settings, you have to add staff first. Please add staff first and change subject settings"></NoteDisplay>
      </div>
    );
  }
}

export default SettingSubjects;
