import React, { useState } from 'react'
import { Spinner } from 'reactstrap';
import { displayErrorAlert } from '../../utils/Utils';
import { axiosGet, axiosPost } from '../../utils/AxiosApi';
import { URL } from '../../utils/Constants';
import swal from "sweetalert";

const ImportAdmission = () => {
    const [spinner, setSpinner] = useState(false);
    const [admissionFile, setAdmissionFile] = useState(null);

    const generateFormat = () => {
        setSpinner(true);

        axiosGet(
            URL.generateAdmissionFormat,
            (response) => {
                if (response.status === 200) {
                    setSpinner(false);
                    window.open(
                        URL.downloadBill + response.data.path,
                        "_blank"
                    );
                }
            },
            (err) => {
                displayErrorAlert(err);
                setSpinner(false);
            }
        );
    };

    const handleChange = (e) => {
        setAdmissionFile(e.target.files[0]);
    };

    const importFile = () => {
        let formData = new FormData();
        if (admissionFile !== null) {
            formData.append("file", admissionFile);

            setSpinner(true);

            axiosPost(
                URL.importAdmission,
                formData,
                (response) => {
                    if (response.status === 200) {
                        document.getElementById("admissionFile").value = null;
                        setSpinner(false);
                        swal("Success", "Admission data imported successfully.");
                    }
                },
                (err) => {
                    swal("Opps!",err.response.data.message);
                    setSpinner(false);
                }
            );
        } else {
            swal("Info", "Empty file cannot be imported");
        }
    };

    return (
        <>
            {spinner ? (
                <div className="fullWindow-Spinner">
                    <div>
                        <Spinner color="white"></Spinner>
                    </div>
                    <div style={{ fontSize: "16px", marginTop: "15px" }}>
                        Please wait...
                    </div>
                </div>
            ) : null}
            <div className="container">
                <div className="d-flex justify-content-between py-2 align-items-center">
                    <div className="">
                        To import the admission data, the format should be according to
                        the system. Please do not change the content of the file.
                        <br /> Generate the admission format here.
                        <br/>
                        <span className="text-danger font-italic">
                            [Note: all columns in the file are mandatory]
                        </span>
                    </div>
                    {
                        <button
                            className="tt-button tt-button-primary"
                            onClick={generateFormat}
                        >
                            Generate Format
                        </button>
                    }
                </div>
                <div className="py-2">
                    <input
                        type="file"
                        name="admissionFile"
                        className="form-control mb-2"
                        onChange={(e) => handleChange(e)}
                        id="admissionFile"
                    />
                    <button
                        className="tt-button tt-button-primary"
                        onClick={importFile}
                    >
                        Import
                    </button>
                </div>
            </div>
        </>
    );
}

export default ImportAdmission
