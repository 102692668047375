import React, { Component } from "react";
import "../../../assets/scss/cardTemplate.scss";
import download from "../../../assets/images/cardTemplates/download.png";
import schoolLogo from "../../../assets/images/logo/logo1.png";

class Template3 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="template3Container">
        <div className="frontTemplate">
          <div className="topGraident">
            <div className="topGraidentDiv1"></div>
            <div className="topGraidentDiv2">
              {this.props.schoolWebAddress ? this.props.schoolWebAddress : ""}
            </div>
          </div>
          <div className="cardTemplateContent">
            <div className="contentTitleFlexDiv">
              <div className="contentTileLogo">
                <img
                  src={
                    this.props.schoolLogo ? this.props.schoolLogo : schoolLogo
                  }
                />
              </div>
              <div className="contentTitleData">
                <p className="contentTitleSchoolName">
                  {this.props.schoolName
                    ? this.props.schoolName
                    : "School Name"}
                </p>
                <p>
                  {this.props.schoolAddress
                    ? this.props.schoolAddress
                    : "School Address"}
                </p>
                <p>
                  {this.props.schoolContact
                    ? this.props.schoolContact
                    : "Tel: Contact Number"}
                </p>
              </div>
            </div>
            <div className="contentDataFlexDiv">
              <div className="contentDataDiv">
                <table className="idTemplate">
                  <tbody>
                    <tr>
                      <th width="30%">Name</th>
                      <th>:</th>
                      <td>
                        {this.props.studentName
                          ? this.props.studentName
                          : "People Name"}
                      </td>
                    </tr>
                    <tr>
                      <th width="30%">Class</th>
                      <th>:</th>
                      <td>
                        {this.props.subtitle
                          ? this.props.subtitle
                          : "Class - Section / Designation"}
                      </td>
                    </tr>
                    <tr>
                      <th width="30%">Address</th>
                      <th>:</th>
                      <td>
                        {this.props.userAddress !== ""
                          ? this.props.userAddress
                          : "----"}
                      </td>
                    </tr>
                    <tr>
                      <th width="30%">Emergency Contact</th>
                      <th>:</th>
                      <td>
                        {this.props?.emergencyContact?.name !== ""
                          ? this.props?.emergencyContact?.name
                          : "--"}
                        {this.props?.emergencyContact?.name !== "" &&
                        this.props?.emergencyContact?.contactNumber !== ""
                          ? ", "
                          : ""}
                        {this.props?.emergencyContact?.contactNumber !== ""
                          ? this.props?.emergencyContact?.contactNumber ||
                            this.props?.emergencyContact?.mobileNumber
                          : "--"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="contentDataDiv2">
                <div className="idImageHolder">
                  <img
                    src={this.props.userImage ? this.props.userImage : download}
                    alt="user image"
                  />
                </div>
                <div className="idSignature idSignatureHolder">
                  <img
                    src={
                      this.props?.signature
                        ? this.props?.signature
                        : "Authorized Signature"
                    }
                  />
                </div>
                <p className="text-center" style={{ width: "80px" }}>
                  {this.props.authorizedSignatureName ||
                    "Sample signature Name"}
                </p>
              </div>
            </div>
          </div>
          <div className="idValidDate">
            <p className="validDateCss">
              <strong>Issued Date: </strong>{" "}
              {this.props.issuedDate ? this.props.issuedDate : "Date"}
            </p>
            <p>
              <strong>Valid Until: </strong>
              {this.props.validityDate ? this.props.validityDate : "Date"}
            </p>
          </div>
        </div>
        <br />
        <div className="backTemplate">
          <div className="topGraident">
            <div className="topGraidentDiv1"></div>
            <div className="topGraidentDiv2">
              {this.props.schoolWebAddress ? this.props.schoolWebAddress : ""}
            </div>
          </div>
          <div className="backNote">
            <p>
              This card must be carried at all times and presented upon request.
              This card holder is responsible for use and missuse of this card.
            </p>
            <p className="warningNoteBack">
              NOT TRANSFERABLE
              <br />
              <span>If found, please return to </span>
            </p>
            <p className="backSchoolTitle">
              <strong>
                {this.props.schoolName ? this.props.schoolName : "School Name"}
              </strong>
            </p>
            <p className="backSchoolData">
              {this.props.schoolAddress
                ? this.props.schoolAddress
                : "School Address"}
            </p>
            <p className="backSchoolData">
              {this.props.schoolContact
                ? this.props.schoolContact
                : "Tel: Contact Number"}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Template3;
