import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import DatePicker from "../UI/DatePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import "../../assets/scss/admission.scss";
import Spinner from "reactstrap/lib/Spinner";
import NepaliDateViewer from "../UI/NepaliDateViewer";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import { convertNepaliDate } from "../../utils/Utils";

class IdentityCluster extends Component {
  state = {
    customFields: [],
    genders: [],
    countrys: [],
  };

  componentDidMount() {
    this.getCustomFields();
    this.getGenders();
    this.getCountry();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.customTableFieldValuesByUsername !==
      this.props.customTableFieldValuesByUsername
    ) {
      this.getCustomFields();
    }
  }

  rereunSetter = () => {
    const customTableFieldValuesByUsername =
      this.props.customTableFieldValuesByUsername;
    let customFields = [...this.state.customFields];
    customFields.forEach((field) => {
      const valueField = customTableFieldValuesByUsername.find(
        (vf) => vf.customTableFieldId === field.id
      );
      field.value = valueField ? valueField.value : null;
    });
    this.setState({ customFields });
  };

  getCountry = () => {
    axiosPost(URL.getCountry, {}, (response) => {
      if (response.status === 200) {
        this.setState({ countrys: response.data.data });
      }
    });
  };

  getCustomFields = () => {
    axiosPost(
      URL.getIdentityCluster,
      {
        isConfig: this.props.config,
      },
      (response) => {
        if (response.status === 200) {
          let data = response.data.data;
          data.forEach((field) => {
            field.key = Math.random();
          });
          this.setState({ customFields: data }, () => {
            if (this.props.customTableFieldValuesByUsername) {
              this.rereunSetter();
            }
          });
        }
      }
    );
  };

  getGenders = () => {
    axiosPost(URL.getGenders, {}, (response) => {
      if (response.status === 200) {
        let data = response.data.data;
        this.setState({ genders: data });
      }
    });
  };

  showPreviousPhoto = (e) => {};

  handleNepaliDefaultValue = () => {
    if(this.props?.customTableField?.year?.toString() === "NaN"){
      return null
    }
    const newDate = `${this.props.customTableField.year}-${
      this.props.customTableField.month === 11 ||
      this.props.customTableField.month === 12 ||
      this.props.customTableField.month === 10
        ? this.props.customTableField.month
        : "0" + this.props.customTableField.month
    }-${this.props.customTableField.day}`;
    const dateValue = convertNepaliDate(newDate);
    return dateValue;
  };

  render() {
    let studentFieldIndex = this.props.studentFieldIndex;
    let customTableFieldIndex = this.props.customTableFieldIndex;
    let stateName = this.props.stateName;
    const customTableField = this.props.customTableField;
    const customTableFieldValues =
      this.props.customTableField?.customTableFieldValues;
    return (
      <div className="card">
        <div className="card-body">
          <div className="tt-group-header">Identity Cluster</div>
          {!this.state.customFields ? (
            <div className="row mb-2">
              <div className="col-md-1"></div>
              <div className="col-md-3">
                <label>
                  <strong>Name </strong>
                  {this.props.inputForm && (
                    <span className="tt-assessment-module-mandatory">*</span>
                  )}
                </label>
              </div>
              <div className="col-md-7">
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  maxLength={255}
                />
              </div>
              <div className="col-md-1"></div>
            </div>
          ) : null}
          {this.state.customFields
            ? this.state.customFields.map((fields, idx) => {
                const fieldName = fields.fieldName;
                return (
                  <div key={fields.key} className="row mb-2">
                    <div className="col-md-1"></div>
                    <div className="col-md-2">
                      <label>
                        <strong>{fields.fieldName} </strong>
                        {this.props.inputForm && fields.isMandatory && (
                          <span className="tt-assessment-module-mandatory">
                            *
                          </span>
                        )}
                      </label>
                    </div>
                    <div className="col-md-8">
                      <input
                        type={fields.fieldTypeName}
                        name={fields.fieldName}
                        // value={fields.value}
                        value={
                          customTableField &&
                          customTableField[fields["fieldName"]]
                        }
                        defaultValue={
                          this.props.userFound
                            ? fields.value
                            : customTableFieldValues &&
                              customTableFieldValues.find(
                                (field) =>
                                  field.customTableFieldId === fields.id
                              )?.value
                        }
                        maxLength={255}
                        onChange={
                          !this.props.config
                            ? (e) =>
                                this.props.handleCustomFieldChange(
                                  e,
                                  studentFieldIndex,
                                  customTableFieldIndex,
                                  stateName,
                                  fields
                                )
                            : null
                        }
                        className={
                          customTableField?.error?.[fields.fieldName]
                            ? "form-control indicate-error"
                            : "form-control"
                        }
                        // className={
                        //   this.props.errors
                        //     ? stateName === "studentContactTableFieldGroups"
                        //       ? this.props.errors[stateName][studentFieldIndex]
                        //         ? this.props.errors[stateName][
                        //             studentFieldIndex
                        //           ][fields.fieldName]
                        //           ? "form-control indicate-error"
                        //           : fields.fieldTypeName === "file"
                        //           ? ""
                        //           : "form-control"
                        //         : this.props.errors[stateName][fields.fieldName]
                        //         ? "form-control indicate-error"
                        //         : "form-control"
                        //       : "form-control"
                        //     : "form-control"
                        // }
                      ></input>
                    </div>
                    {/* <div className="col-md-1">
                      {this.props.errors ? (
                        this.props.errors[stateName][fields.fieldName] ? (
                          <span className="customfield-error-text">
                            mandatory
                          </span>
                        ) : null
                      ) : null}
                    </div> */}
                  </div>
                );
              })
            : null}
          <div className="row mb-2">
            <div className="col-md-1"></div>
            <div className="col-md-2">
              <label>
                <strong>Date of birth </strong>

                {this.props.inputForm && (
                  <span className="tt-assessment-module-mandatory">*</span>
                )}
              </label>
            </div>
            <div className={this.props.adbsToggler ? "col-md-6" : "col-md-8"}>
              {this.props.identityClusterDatePickerToggle ? (
                <NepaliDateViewer
                  config={this.props.config}
                  bday={true}
                  identity={true}
                  customTableField={customTableField}
                  studentFieldIndex={studentFieldIndex}
                  customTableFieldIndex={customTableFieldIndex}
                  handleDateChange={this.props.handleDateChange}
                  stateName={stateName}
                  errors={this.props.errors}
                  dateOfBirth={this.props.dateOfBirth || ""}
                  editMode={this.props.editMode}
                />
              ) : (
                <DatePicker
                  config={this.props.config}
                  bday={true}
                  identity={true}
                  customTableField={this.props.customTableField}
                  studentFieldIndex={studentFieldIndex}
                  customTableFieldIndex={customTableFieldIndex}
                  handleDateChange={this.props.handleDateChange}
                  stateName={stateName}
                  errors={this.props.errors}
                  dateOfBirth={this.props.dateOfBirth || ""}
                  editMode={this.props.editMode}
                />
              )}
            </div>

            {this.props.adbsToggler ? (
              <div className="col-md-2 ">
                <div className="d-flex togglerIcon mt-1">
                  <div>
                    <span>
                      {" "}
                      <b>A.D.</b>
                    </span>
                    <label className="switch">
                      <input
                        name="identityClusterDatePickerToggle"
                        type="checkbox"
                        defaultChecked={
                          this.props.identityClusterDatePickerToggle
                        }
                        checked={this.props.identityClusterDatePickerToggle}
                        onChange={this.props.icToggleDatePicker}
                      />
                      <span className="slider round"></span>
                    </label>{" "}
                    <span>
                      {" "}
                      <b>B.S.</b>
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="col-md-1">
              <div className="position-relative p-1">
                <div className="">
                  {this.props.identityClusterDatePickerToggle ? (
                    <Calendar
                      hideDefaultValue={true}
                      // defaultDate={this.handleNepaliDefaultValue()}
                      // className="form-control"
                      onChange={
                        !this.props.config
                          ? (e) =>
                              this.props.handleBdayChange(
                                {
                                  target: { value: e.adDate },
                                },
                                studentFieldIndex,
                                customTableFieldIndex,
                                stateName,
                              )
                          : null
                      }
                    />
                  ) : (
                    <input
                      type="date"
                      name="doe"
                      id="bday"
                      className="datePicker-ad"
                      // className="bday-picker"
                      min={"1900-01-01"}
                      max={new Date().toISOString().substring(0, 10)}
                      onChange={
                        !this.props.config
                          ? (e) =>
                              this.props.handleBdayChange(
                                e,
                                studentFieldIndex,
                                customTableFieldIndex,
                                stateName
                              )
                          : null
                      }
                    />
                  )}
                </div>
                <FontAwesomeIcon icon={faCalendarAlt} className="bday-fa" />
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-1"></div>
            <div className="col-md-2">
              <label>
                <strong>Gender </strong>
                {this.props.inputForm && (
                  <span className="tt-assessment-module-mandatory">*</span>
                )}
              </label>
            </div>
            <div className="col-md-8">
              <select
                className={
                  this.props.errors
                    ? stateName === "admissionTableFieldGroups"
                      ? this.props.errors[stateName]
                        ? this.props.errors[stateName].gender
                          ? "form-control indicate-error"
                          : "form-control"
                        : this.props.errors[stateName][studentFieldIndex].gender
                        ? "form-control indicate-error"
                        : "form-control"
                      : this.props.errors[stateName][studentFieldIndex].gender
                      ? "form-control indicate-error"
                      : "form-control"
                    : "form-control"
                }
                name="gender"
                onChange={
                  !this.props.config
                    ? (e) =>
                        this.props.handleCustomFieldChange(
                          e,
                          studentFieldIndex,
                          customTableFieldIndex,
                          stateName
                        )
                    : null
                }
                value={customTableField && customTableField.gender}
              >
                {this.props.editMode ? null : (
                  <option value="" disabled selected>
                    Choose a gender
                  </option>
                )}
                {this.state.genders.map((gender) => (
                  <option value={gender.gender}>{gender.gender}</option>
                ))}
              </select>
            </div>
            <div className="col-md-1"></div>
          </div>
          <div className="row mb-2">
            <div className="col-md-1"></div>
            <div className="col-md-2">
              <label>
                <strong>Nationality</strong>
                {this.props.inputForm && (
                  <span className="tt-assessment-module-mandatory">*</span>
                )}
              </label>
            </div>
            <div className="col-md-8">
              <select
                name="nationality"
                className={
                  this.props.errors
                    ? stateName === "admissionTableFieldGroups"
                      ? this.props.errors[stateName]
                        ? this.props.errors[stateName].nationality
                          ? "form-control indicate-error"
                          : "form-control"
                        : this.props.errors[stateName][studentFieldIndex]
                            .nationality
                        ? "form-control indicate-error"
                        : "form-control"
                      : this.props.errors[stateName][studentFieldIndex]
                          .nationality
                      ? "form-control indicate-error"
                      : "form-control"
                    : "form-control"
                }
                onChange={
                  !this.props.config
                    ? (e) =>
                        this.props.handleCustomFieldChange(
                          e,
                          this.props.studentFieldIndex,
                          this.props.customTableFieldIndex,
                          this.props.stateName
                        )
                    : null
                }
                value={customTableField && customTableField.nationality}
              >
                {this.props.editMode ? null : (
                  <option value="" selected disabled>
                    Choose a nationality
                  </option>
                )}
                {this.state.countrys.map((country, idx) => {
                  return (
                    <option key={idx} value={country.id}>
                      {country.nationality ? country.nationality : country.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-md-1"></div>
          </div>
          <div className="row mb-2">
            <div className="col-md-1"></div>
            <div className="col-md-2">
              <label>
                <strong>Photo: </strong>
              </label>
            </div>
            <div className="col-md-8">
              <input
                type="file"
                name="photo"
                accept="image/png, image/jpeg"
                onChange={
                  !this.props.config
                    ? (e) =>
                        this.props.handleFileChange(
                          e,
                          this.props.studentFieldIndex,
                          this.props.customTableFieldIndex,
                          this.props.stateName
                        )
                    : null
                }
                disabled={this.props.userFound && this.props.recruitmentImage}
              />
            </div>
            <div className="col-md-1"></div>
          </div>

          {/* {!this.props.student ? ( */}
            <div className="row mb-2">
              <div className="col-md-1"></div>
              <div className="col-md-2">
                <label>
                  <strong>Email</strong>
                  {/* {this.props.inputForm && (
                    <span className="tt-assessment-module-mandatory">*</span>
                  )} */}
                </label>
              </div>
              <div className="col-md-8">
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  // {
                  //   this.props.errors
                  //     ? stateName === "admissionTableFieldGroups"
                  //       ? this.props.errors[stateName]
                  //         ? this.props.errors[stateName].email
                  //           ? "form-control indicate-error"
                  //           : "form-control"
                  //         : this.props.errors[stateName][studentFieldIndex]
                  //             .email
                  //         ? "form-control indicate-error"
                  //         : "form-control"
                  //       : this.props.errors[stateName][studentFieldIndex].email
                  //       ? "form-control indicate-error"
                  //       : "form-control"
                  //     : "form-control"
                  // }
                  defaultValue={
                    stateName === "studentContactTableFieldGroups"
                      ? customTableField?.email
                      : this.props.email
                  }
                  value={customTableField?.email}
                  onChange={
                    !this.props.config
                      ? (e) =>
                          this.props.handleCustomFieldChange(
                            e,
                            studentFieldIndex,
                            customTableFieldIndex,
                            stateName
                          )
                      : null
                  }
                  // onBlur={(e) =>
                  //   this.props.checkIfEmailAvailable(e, customTableField.email)
                  // }
                ></input>
              </div>
              <div className="col-md-1">
                {this.props.emailFetching ? (
                  <Spinner color="primary" />
                ) : this.props.isEmailAvailable &&
                  customTableField?.email &&
                  customTableField?.email !== "" ? (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="primary-color username-icon"
                  />
                ) : (
                  this.props.isEmailAvailable === false &&
                  customTableField?.email !== "" && (
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      id="incorrect"
                      className="danger-color username-icon"
                    />
                  )
                )}
              </div>
            </div>
          {/* ) : null} */}

          <div className="row mb-2">
            <div className="col-md-1"></div>
            <div className="col-md-2">
              <label>
                <strong>Mobile number</strong>
              </label>
            </div>
            <div className="col-md-8">
              <input
                type="number"
                name="mobileNumber"
                className="form-control"
                defaultValue={
                  stateName === "studentContactTableFieldGroups"
                    ? customTableField?.mobileNumber
                    : this.props.mobileNumber
                }
                value={customTableField?.mobileNumber}
                onChange={
                  !this.props.config
                    ? (e) =>
                        this.props.handleCustomFieldChange(
                          e,
                          studentFieldIndex,
                          customTableFieldIndex,
                          stateName
                        )
                    : null
                }
              ></input>
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default IdentityCluster;
