import ad2bs from "ad-bs-converter";
import moment from "moment";
import React, { Component } from "react";

class NepaliDateViewer extends Component {
  state = {};

  componentDidMount() {
    this.setNepaliDateView();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.customTableField.day !== this.props.customTableField?.day ||
      prevProps.customTableField.month !== this.props.customTableField?.month
    ) {
      this.setNepaliDateView();
    }
    // if (prevState.customTableField.day !== this.state.customTableField.day) {
    //   this.setNepaliDateView();
    // }
  }

  setNepaliDateView = () => {
    if (
      this.props?.customTableField?.year?.toString() === "NaN" ||
      this.props?.customTableField?.year === null ||
      this.props?.customTableField?.month?.toString() === "NaN" ||
      this.props?.customTableField?.month === null ||
      this.props?.customTableField?.day?.toString() === "NaN" ||
      this.props?.customTableField?.day === null
    ) {
      return null;
    }
    const engDate = `${this.props.customTableField.year}-${
      this.props.customTableField.month === 11 ||
      this.props.customTableField.month === 12 ||
      this.props.customTableField.month === 10
        ? this.props.customTableField.month
        : "0" + this.props.customTableField.month
    }-${this.props.customTableField.day}`;

    const nepaliDate = ad2bs.ad2bs(moment(engDate).format("YYYY/MM/DD"));
    this.setState({
      customDate: nepaliDate.ne,
      customTableField: this.props.customTableField,
    });
  };

  render() {
    let errors = this.props.errors;

    let stateName = this.props.stateName;
    let studentFieldIndex = this.props.studentFieldIndex;
    return (
      <div className="row">
        <div className="col">
          <input
            type="text"
            disabled
            // className="form-control"
            placeholder="बर्ष"
            className={
              this.props.identity
                ? errors
                  ? stateName === "admissionTableFieldGroups"
                    ? errors[stateName]
                      ? errors[stateName].year
                        ? "form-control indicate-error"
                        : this.props.bday
                        ? "form-control"
                        : errors.dateOfEstablishment.year
                        ? "form-control indicate-error"
                        : "form-control"
                      : errors[stateName][studentFieldIndex].year
                      ? "form-control indicate-error"
                      : "form-control"
                    : "form-control"
                  : "form-control"
                : "form-control"
            }
            value={
              this.state.customDate?.day
                ? this.state.customDate?.year || ""
                : ""
            }
          />
        </div>
        <div className="col">
          <input
            type="text"
            disabled
            placeholder="महिना"
            className={
              this.props.identity
                ? errors
                  ? stateName === "admissionTableFieldGroups"
                    ? errors[stateName]
                      ? errors[stateName].month
                        ? "form-control indicate-error"
                        : this.props.bday
                        ? "form-control"
                        : errors.dateOfEstablishment.month
                        ? "form-control indicate-error"
                        : "form-control"
                      : errors[stateName][studentFieldIndex].month
                      ? "form-control indicate-error"
                      : "form-control"
                    : "form-control"
                  : "form-control"
                : "form-control"
            }
            value={
              this.state.customDate?.day
                ? this.state.customDate?.strMonth || ""
                : ""
            }
          />
        </div>
        <div className="col">
          <input
            type="text"
            disabled
            placeholder="दिन"
            className={
              this.props.identity
                ? errors
                  ? stateName === "admissionTableFieldGroups"
                    ? errors[stateName]
                      ? errors[stateName].day
                        ? "form-control indicate-error"
                        : this.props.bday
                        ? "form-control"
                        : errors.dateOfEstablishment.day
                        ? "form-control indicate-error"
                        : "form-control"
                      : errors[stateName][studentFieldIndex].day
                      ? "form-control indicate-error"
                      : "form-control"
                    : "form-control"
                  : "form-control"
                : "form-control"
            }
            value={this.state.customDate?.day || ""}
          />
        </div>
      </div>
    );
  }
}

export default NepaliDateViewer;
