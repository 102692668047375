import React from 'react'

function AdmitCardRoutine(props) {
    return (
        <>
            {
                (props?.classValue && props?.assessmentSubjectValue) ?
                    (
                        <>
                            {props?.routineData?.length > 0 ?
                                (
                                    <>
                                        <table className='table table-bordered table-striped' border="1" style={{ borderCollapse: 'collapse', width: '100%' }}>
                                            <thead className="tt-group-header">
                                                <tr>
                                                    <th style={{ padding: '8px', textAlign: 'left' }}>
                                                        <div className='d-flex align-items-center justify-content-center w-100'>
                                                            Date
                                                        </div>
                                                    </th>
                                                    <th style={{ padding: '8px', textAlign: 'left' }}>
                                                        <div className='d-flex align-items-center justify-content-center w-100'>
                                                            Subjects
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {props?.filtereduniqueDates?.map((date, index) => (
                                                    <tr key={index}>
                                                        <td className="px-1 py-1" style={{ padding: '8px', textAlign: 'left' }}>
                                                            <div className='mt-1 d-flex align-items-center justify-content-center'>
                                                                {date}
                                                            </div>
                                                        </td>
                                                        <td className="px-1 py-1" style={{ padding: '8px', textAlign: 'left' }}>
                                                            <div className='d-flex flex-column align-items-center w-100'>
                                                                <span>
                                                                    {props?.filteredTable[date]?.join('/')}
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </>) :
                                (
                                    <>
                                        No records found.
                                    </>)}

                        </>) :
                    (
                        <>
                            No records found.
                        </>)
            }
        </>
    )
}

export default AdmitCardRoutine