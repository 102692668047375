import React from 'react'

function ExamModuleView(props) {
  return (
    <>
      {
        (props?.classValue && props?.assessmentSubjectValue) ?
          (
            <>
              {props?.routineData?.length > 0 ?
                (
                  <>
                    {/* <table className='table table-bordered table-striped' border="1" style={{ borderCollapse: 'collapse', width: '100%' }}>
                      <thead className="tt-group-header">
                        <tr>
                          <th style={{ padding: '8px', textAlign: 'left' }}>
                            <div className='d-flex align-items-center justify-content-center w-100'>
                              Class
                            </div>
                          </th>
                          {props?.filtereduniqueDates?.map((date) => (
                            <th key={date} style={{ padding: '8px', textAlign: 'left' }}>
                              <div className='d-flex align-items-center justify-content-center w-100'>
                                {date}
                              </div>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {
                          props?.filteredTable?.map((row) => (
                            <tr key={row.yearLevelName}>
                              <td style={{ padding: '8px', textAlign: 'left' }}>
                                <div className='mt-1 d-flex align-items-center justify-content-center'>
                                  {row.yearLevelName}
                                </div>
                              </td>
                              {props?.filtereduniqueDates?.map((date) => (
                                <>
                                  <td key={date} style={{ padding: '8px', textAlign: 'left' }}>
                                    <div className='d-flex flex-column align-items-center w-100'>
                                      {
                                        row[date]?.map((item, idx) => {
                                          return (
                                            <>
                                              <div key={idx} className="p-1 d-flex flex-row align-items-center">
                                                {item}
                                              </div>
                                            </>
                                          )
                                        })
                                      }
                                    </div>
                                  </td>
                                </>
                              ))}
                            </tr>
                          ))
                        }
                      </tbody>
                    </table> */}
                    {/* new table */}
                    <table className=' table-bordered table-striped print-table' border="1" style={{ borderCollapse: 'collapse', width: '100%' }}>
                      <thead className="tt-group-header">
                        <tr>
                          <th style={{ padding: '8px', textAlign: 'left' }}>
                            <div className='d-flex align-items-center text-center w-100'>
                              Class
                            </div>
                          </th>
                          {props?.filtereduniqueDates?.map((date) => (
                            <th key={date} style={{ padding: '8px', textAlign: 'left' }}>
                              <div className='d-flex align-items-center text-center w-100'>
                                {date}
                              </div>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {
                          props?.filteredTable && props?.filteredTable?.map((row) => (
                            <tr key={row.yearLevelName}>
                              <td style={{ padding: '8px', textAlign: 'left' }}>
                                <div className='mt-1 d-flex flex-column align-items-center justify-content-center'>
                                  <span>{row.yearLevelName}</span>
                                  {/* <span style={{ fontSize: '12px' }}>{row?.yearLevelName?.slice(3, -1)}</span> */}
                                </div>
                              </td>
                              {props?.filtereduniqueDates?.map((date) => (
                                <td key={date} style={{ padding: '8px', textAlign: 'left' }}>
                                  <div className='d-flex flex-column align-items-center w-100'>
                                    {row[date] && row[date].length > 0 ? (
                                      row[date].map((item, idx) => (
                                        <div key={idx} className="p-1 d-flex flex-column align-items-center text-center">
                                          <span style={{ fontWeight: 'bold' }}>{item.subject}</span>
                                          {item.theoryTime && <span>{item.theoryTime}</span>}
                                          {item.practicalTime && <span>{item.practicalTime}</span>}
                                        </div>
                                      ))
                                    ) : (
                                      <div className="p-1 d-flex flex-row align-items-center text-center">
                                        -
                                      </div>
                                    )}
                                  </div>
                                </td>
                              ))}
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </>) :
                (
                  <>
                    No records found.
                  </>)}

            </>) :
          (
            <>
              No records found.
            </>)
      }
    </>
  )
}

export default ExamModuleView