import React, { Component } from "react";

export default class DatePicker extends Component {
  state = {
    years: [],
    months: [],
    days: [],
    currentYear: null,
    selectedYear: null,
    selectedMonth: null,
    selectedDay: null,
  };

  componentDidMount() {
    var currentYear = new Date().getFullYear();
    const startingYear = this.props.bday ? 1945 : 1945;
    const years = new Array(currentYear + 1 - startingYear)
      .fill()
      .map((d, i) => startingYear + i);

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const numberOfDays = this.generateDays(currentYear, 1); //month index + 1
    const days = new Array(numberOfDays).fill().map((d, i) => i + 1);

    this.setState({
      years,
      months,
      days,
      selectedYear: currentYear,
      selectedMonth: 0,
      currentYear,
    });
  }

  generateDays = (year, month) => {
    // const year = this.state.currentYear;
    // var month = parseInt(selectMonth.val()) + 1;
    //get the last day, so the number of days in that month
    var days = new Date(year, month, 0).getDate();
    return days;
  };

  handleYearChange = (e) => {
    const selectedYear = parseInt(e.target.value);
    const month = this.state.selectedMonth;
    const numberOfDays = this.generateDays(selectedYear, month);

    const days = new Array(numberOfDays).fill().map((d, i) => i + 1);

    this.setState({ days, selectedYear }, () => {
      // method passes from props
      this.props.handleDateChange(
        "year",
        selectedYear,
        this.props.studentFieldIndex,
        this.props.customTableFieldIndex,
        this.props.stateName
      );
    });
  };

  handleMonthChange = (e) => {
    const year = this.state.selectedYear,
      selectedMonth = parseInt(e.target.value);
    const month = selectedMonth;

    const numberOfDays = this.generateDays(year, month);
    const days = new Array(numberOfDays).fill().map((d, i) => i + 1);

    this.setState({ days, selectedMonth }, () => {
      // method passes from props
      this.props.handleDateChange(
        "month",
        selectedMonth,
        this.props.studentFieldIndex,
        this.props.customTableFieldIndex,
        this.props.stateName
      );
    });
    // this.setState()
  };

  handleDayChange = (e) => {

    
    const selectedDay = parseInt(e.target.value);
    this.setState({ selectedDay }, () => {
      this.props.handleDateChange(
        "day",
        selectedDay,
        this.props.studentFieldIndex,
        this.props.customTableFieldIndex,
        this.props.stateName
      );
    });
  };

  render() {
    let errors = this.props.errors;
    let renderErrorText = this.props.renderErrorText;
    let customTableField = this.props.customTableField;
    let stateName = this.props.stateName;
    let studentFieldIndex = this.props.studentFieldIndex;
    return (
      <>
        <div className="row datePicker-ui">
          <div className="col">
            <select
              className={
                this.props.identity
                  ? errors
                    ? stateName === "admissionTableFieldGroups"
                      ? errors[stateName]
                        ? errors[stateName].year
                          ? "form-control indicate-error"
                          : this.props.bday
                          ? "form-control"
                          : errors.dateOfEstablishment.year
                          ? "form-control indicate-error"
                          : "form-control"
                        : errors[stateName][studentFieldIndex].year
                        ? "form-control indicate-error"
                        : "form-control"
                      : errors[stateName][studentFieldIndex].year
                      ? "form-control indicate-error"
                      : "form-control"
                    : "form-control"
                  : "form-control"
              }
              name="year"
              value={
                this.props.userManager
                  ? this.props.DOE.year
                  : this.props.bday
                  ? customTableField !== undefined
                    ? customTableField?.year?.toString().trim() === "NaN"
                      ? null
                      : customTableField.year
                    : null
                  : this.props.DOE.year
              }

       
              onChange={!this.props.config ? this.handleYearChange : null}
            >
              {/* {this.props.editMode ? null : (
                <option value={null} selected disabled>
                  Year
                </option>
              )} */}
                
                <option value={null} selected disabled>
                  Year
                </option>
              
              {this.state.years
                ? this.state.years.map((year) => (
                    <option value={year}>{year}</option>
                  ))
                : null}
            </select>
          </div>
          {this.props.bday
            ? null
            : errors.dateOfEstablishment.year
            ? renderErrorText()
            : null}
          <div className="col">
            {/* Months */}
            <select
              required
              className={
                this.props.identity
                  ? errors
                    ? stateName === "admissionTableFieldGroups"
                      ? errors[stateName]
                        ? errors[stateName].month
                          ? "form-control indicate-error"
                          : this.props.bday
                          ? "form-control"
                          : errors.dateOfEstablishment.month
                          ? "form-control indicate-error"
                          : "form-control"
                        : errors[stateName][studentFieldIndex].month
                        ? "form-control indicate-error"
                        : "form-control"
                      : errors[stateName][studentFieldIndex].year
                      ? "form-control indicate-error"
                      : "form-control"
                    : "form-control"
                  : "form-control"
              }
              value={
                this.props.userManager
                  ? this.props.DOE.month
                  : !this.props.config
                  ? this.props.bday
                    ? customTableField !== undefined
                      ? customTableField?.month?.toString() === "NaN"
                        ? null
                        : customTableField.month
                      : null
                    : this.props.DOE.month
                  : null
              }
              onChange={!this.props.config ? this.handleMonthChange : null}
            >
              <option selected disabled>
                Month
              </option>
              {this.state.months
                ? this.state.months.map((month, i) => (
                    <option value={i + 1}>{month}</option>
                  ))
                : null}
            </select>
          </div>
          {this.props.bday
            ? null
            : errors.dateOfEstablishment.month
            ? renderErrorText()
            : null}
          <div className="col">
            {/* Days */}
            <select
              required
              className={
                this.props.identity
                  ? errors
                    ? stateName === "admissionTableFieldGroups"
                      ? errors[stateName]
                        ? errors[stateName].day
                          ? "form-control indicate-error"
                          : this.props.bday
                          ? "form-control"
                          : errors.dateOfEstablishment.day
                          ? "form-control indicate-error"
                          : "form-control"
                        : errors[stateName][studentFieldIndex].day
                        ? "form-control indicate-error"
                        : "form-control"
                      : errors[stateName][studentFieldIndex].year
                      ? "form-control indicate-error"
                      : "form-control"
                    : "form-control"
                  : "form-control"
              }
              value={
                this.props.userManager
                  ? this.props.DOE.day
                  : !this.props.config
                  ? this.props.bday
                    ? customTableField !== undefined
                      ? customTableField?.day?.toString() === "NaN"
                        ? null
                        : customTableField.day
                      : null
                    : this.props.DOE.day
                  : null
              }
              onChange={!this.props.config ? this.handleDayChange : null}
            >
              <option selected disabled>
                Day
              </option>
              {this.state.days
                ? this.state.days.map((day) => (
                    <option value={day}>{day}</option>
                  ))
                : null}
            </select>
          </div>
        </div>
      </>
    );
  }
}
